import React from 'react';
import styled from 'styled-components';
import { DesktopTwoColumns } from '../Columns/DesktopTwoColumns';
import { ExpertPanel } from '../Panels/Expert';
import { WithSubjectProps } from './withSubject.hoc';

const ExpertModal = styled(ExpertPanel as React.ComponentType<any>)`
  border: 1px solid #d9d9d7;
  padding-top: 48px;
  align-items: center;
  width: 278px;
  margin: 0 auto;
  border-radius: 4px;

  & ~ div {
    flex: 1;
  }
`;
const StyledExpertPanel = styled(ExpertPanel as React.ComponentType<any>)`
  width: 278px;
`;

type ExpertPanelProps = Pick<WithSubjectProps, 'subject'>;

export const WithExpertPanel = <TProps extends ExpertPanelProps>(Component: React.FC<TProps>): React.FC<TProps> => {
  const hoc: React.FC<TProps> = (props) => {
    const { subject } = props;

    return (
      <DesktopTwoColumns
        right={
          subject?.assignedExpert && (
            <StyledExpertPanel
              userId={subject.assignedExpert.id}
              name={subject.assignedExpert.name}
              phoneNumber={subject.assignedExpert.phone}
              email={subject.assignedExpert.email}
            />
          )
        }
        fabTracking="project-fab"
        fabPopupContent={
          subject?.assignedExpert && (
            <ExpertModal
              userId={subject.assignedExpert.id}
              name={subject.assignedExpert.name}
              phoneNumber={subject.assignedExpert.phone}
              email={subject.assignedExpert.email}
            />
          )
        }
      >
        <Component {...props} />
      </DesktopTwoColumns>
    );
  };

  hoc.displayName = `${Component.displayName}WithExpertPanel`;

  return hoc;
};
