import { Tooltip } from 'antd';
import React from 'react';
import { ReactComponent as QuestionIcon } from '../../svg/question.svg';
import styled from 'styled-components';

export type HelpIconProps = {
  id: string;
};

const StyledSpan = styled.span`
  cursor: pointer;
  pointer-events: auto;
  overflow: visible;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;

const StyledQuestionIcon = styled(QuestionIcon as React.ComponentType<any>)`
  width: 100%;
  height: 100%;
  padding: 3px;
  margin: 3px;
  position: relative;
  bottom: 2px;
  left: 3px;
  border-radius: 100%;
`;

export const HelpIcon: React.FC<React.HTMLProps<HelpIconProps>> = ({ children, id, ...props }): JSX.Element => {
  return (
    <Tooltip title={children} trigger={['click', 'hover']} id={id}>
      {' '}
      <StyledSpan
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        {...(props as any)}
      >
        <StyledQuestionIcon title={''} />
      </StyledSpan>
    </Tooltip>
  );
};
