import Modal from 'react-modal';
import styled from 'styled-components';

export const StyledModal = styled(Modal as React.ComponentType<any>)`
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  position: absolute;
  transform: translate(-50%, -50%);
  &:focus {
    outline: none;
  }
`;
