import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { ContactPanel, Copy, Layout } from '../../components';
import { DesktopTwoColumns } from '../../components/Columns/DesktopTwoColumns';
import { ApprovedPhoneNumber } from '../../components/Form/constants';
import { ProjectForm } from '../../components/Form/ProjectForm';
import { useSubject } from '../../hooks/useSubject';
import Desktop1xJpg from '../../images/quote-form-hero/desktop-1x.jpg';
import Desktop2xJpg from '../../images/quote-form-hero/desktop-2x.jpg';
import Mobile1xJpg from '../../images/quote-form-hero/mobile-1x.jpg';
import Mobile2xJpg from '../../images/quote-form-hero/mobile-2x.jpg';
import Tablet1xJpg from '../../images/quote-form-hero/tablet-1x.jpg';
import Tablet2xJpg from '../../images/quote-form-hero/tablet-2x.jpg';

const ContactModal = styled(ContactPanel as React.ComponentType<any>)`
  border: 1px solid #d9d9d7;
  padding-top: 48px;
  align-items: center;
  & > h2 {
    text-align: center;
  }
`;

const Page = (): JSX.Element => {
  const history = useHistory();
  const subject = useSubject();
  const title = subject === 'projects' ? 'Submit a Project' : 'Request a Fee Proposal';
  const projectPanel = (
    <ContactPanel
      heading="Would you like any assistance?"
      text={'Feel free to give our team a call'}
      phoneNumber={ApprovedPhoneNumber}
    />
  );
  const [requestId, setProjectRequestId] = useState<string>('');

  const onSubmitted = () => {
    if (subject === 'projects') history.push('/project-requested');
    if (subject === 'quotes') history.push('/quote-requested');
  };

  useEffect(() => {
    const uuid = uuidv4();
    setProjectRequestId(uuid);
  }, []);

  return (
    <Layout
      mobile={Mobile1xJpg}
      mobile2x={Mobile2xJpg}
      tablet={Tablet1xJpg}
      tablet2x={Tablet2xJpg}
      desktop={Desktop1xJpg}
      desktop2x={Desktop2xJpg}
      heading={title}
      showLoginBanner={true}
    >
      <DesktopTwoColumns
        right={projectPanel}
        fabTracking="project-request-fab"
        fabPopupContent={
          <ContactModal
            heading="Would you like any assistance?"
            text={'Feel free to give our team a call'}
            phoneNumber={ApprovedPhoneNumber}
          />
        }
      >
        {
          <>
            <Copy>
              {`The first step to getting your development approved is to send through your plans and we'll respond with a quote to arrange your approval.`}
            </Copy>
            <ProjectForm onSubmitted={onSubmitted} requestId={requestId} />
          </>
        }
      </DesktopTwoColumns>
    </Layout>
  );
};

export const ProjectRequestPage = Page;
