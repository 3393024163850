import React from 'react';
import styled from 'styled-components';
import { Checkbox } from 'antd';

const CheckBoxContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direct: row;
`;
const StyledSwitch = styled(Checkbox as React.ComponentType<any>)`
  margin-right: 10px;
`;

type Props = {
  name: string;
  label: string;
  onChange: (checked: boolean) => void;
  showManualAddress: boolean;
};

export const Switch = ({ name, label, onChange, showManualAddress }: Props): JSX.Element => {
  const handleChange = (checked: boolean) => {
    onChange && onChange(checked);
  };

  return (
    <CheckBoxContainer>
      <StyledSwitch
        id={name}
        onChange={(e: any) => handleChange(e.target.checked)}
        checked={showManualAddress}
      ></StyledSwitch>
      <label htmlFor={name}>
        <p>Enter address manually?</p>
      </label>
    </CheckBoxContainer>
  );
};
