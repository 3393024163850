import React from 'react';
import styled from 'styled-components';
import { breakpoint } from 'styled-components-breakpoint';
import { Layout } from '../../components';
import Desktop1xJpg from '../../images/quote-form-hero/desktop-1x.jpg';
import Desktop2xJpg from '../../images/quote-form-hero/desktop-2x.jpg';
import Mobile1xJpg from '../../images/quote-form-hero/mobile-1x.jpg';
import Mobile2xJpg from '../../images/quote-form-hero/mobile-2x.jpg';
import Tablet1xJpg from '../../images/quote-form-hero/tablet-1x.jpg';
import Tablet2xJpg from '../../images/quote-form-hero/tablet-2x.jpg';
import { ReactComponent as TickIcon } from '../../svg/tick-circle.svg';
import { BaseSuccess } from '../../components/BaseSuccess';

const StyledTick = styled(TickIcon as React.ComponentType<any>)`
  rect {
    fill: transparent;
  }
  g {
    fill: white;
  }
  ${breakpoint('mobile')`
  width: 40px;
  height: 40px;
  `}
  ${breakpoint('tablet')`
  width: 50px;
  height: 50px;
  `}
  ${breakpoint('desktop')`
  width: 60px;
  height: 60px;
  `}
`;

export const ProjectConfirmation = (): JSX.Element => {
  const heading = (
    <div>
      {' '}
      <StyledTick /> Project request sent
    </div>
  );
  const input = `# What happens next?
   1. We will be in touch within one business day to confirm the project details.
   2. Upon confirmation, we'll get in touch to discuss the next steps.`;
  return (
    <Layout
      mobile={Mobile1xJpg}
      mobile2x={Mobile2xJpg}
      tablet={Tablet1xJpg}
      tablet2x={Tablet2xJpg}
      desktop={Desktop1xJpg}
      desktop2x={Desktop2xJpg}
      heading={heading}
    >
      <BaseSuccess
        title={'Thanks!'}
        description={'An approved team member will be in contact with you shortly.'}
        markdown={input}
      ></BaseSuccess>
    </Layout>
  );
};
