import styled from 'styled-components';
import { breakpoint } from 'styled-components-breakpoint';

import { BaseButton, BaseButtonProps } from '../BaseButton';
import { colorBlue, colorWhite, outlineBlue } from '../../../theme.selectors';

export type SecondaryButtonProps = BaseButtonProps;

export const SecondaryButton = styled(BaseButton as React.ComponentType<any>)`
  box-sizing: border-box;
  height: 48px;
  min-width: 186px;
  padding: 0 16px;
  border: 2px solid ${colorBlue};
  color: ${colorBlue};
  border-radius: 28px;
  background-color: ${colorWhite};
  cursor: pointer;
  &:focus {
    outline: 2px solid ${outlineBlue};
    outline-offset: 1px;
  }
  &:active {
    outline: none;
  }
  &:hover:enabled {
    border: none;
    color: #fff;
    background-color: ${colorBlue};
  }

  &:active:enabled {
    border: none;
    color: #fff;
    background-color: ${colorBlue};
    opacity: 0.6;
  }

  &:disabled {
    opacity: 0.4;
    cursor: auto;
  }

  ${breakpoint('desktop')`
    padding: 0 24px;
  `}
`;
