import React from 'react';
import { Link } from '../../Link/Link';
import { grayText, colorBlack } from '../../../theme.selectors';
import { ReactComponent as PhoneIcon } from '../../../svg/phone.svg';
import breakpoint, { map } from 'styled-components-breakpoint';
import styled from 'styled-components';
import { theme } from '../../../theme';
import { Panel } from '../BasePanel';
import { baseStyles, fontSize, lineHeight } from '../../Heading/Heading';

type ContactProps = {
  heading: string;
  text: string;
  phoneNumber?: string;
  className?: string;
};

const FaintHeading = styled.div`
  color: ${grayText};
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin: 1em 0;
`;

const PhoneDiv = styled.div`
  margin-left: 15px;
  color: ${colorBlack};
`;

const PanelHeading = styled.h2`
  ${baseStyles};
  margin: 0;
  font-weight: 700;
  ${map(theme.lineHeights.h2, lineHeight)};
  ${map(theme.fontSizes.h2, fontSize)};

  font-size: 32px;
  line-height: 36px;
  ${breakpoint('mobile')`
    text-align: center;
  `}
  ${breakpoint('tablet')`
    text-align: inherit;
  `}
`;

export const ContactPanel = ({ heading, text, phoneNumber, className }: ContactProps): JSX.Element => {
  return (
    <Panel className={className}>
      <PanelHeading>{heading}</PanelHeading>
      <FaintHeading>{text}</FaintHeading>
      {phoneNumber && (
        <Link
          style={{
            display: 'flex',
            alignItems: 'center',
            textDecoration: 'none',
            margin: '0',
            fontWeight: 'bold',
            fontSize: '32px',
            lineHeight: '36px',
          }}
          href={`tel:${phoneNumber}`}
        >
          <PhoneIcon /> <PhoneDiv>{phoneNumber}</PhoneDiv>
        </Link>
      )}
    </Panel>
  );
};
