import React, { useState } from 'react';
import { Props as ReactModalProps } from 'react-modal';
import { PrimaryButton, SecondaryButton } from '../../Button';
import { BaseModal } from '../BaseModal';
import { useModal } from 'react-modal-hook';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

export type ConfirmModalProps = {
  title: string;
  description?: string;
  confirmActionText?: string;
  cancelActionText?: string;
  trackingPrefix: string;
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
};
const StyledPrimaryButton = styled(PrimaryButton as React.ComponentType<any>)`
  min-width: 177px;
  width: 100%;
  ${breakpoint('tablet')`
max-width: 177px
  `}
`;
const StyledSecondary = styled(SecondaryButton as React.ComponentType<any>)`
  font-size: 18px;
  letter-spacing: 0;
  line-height: 20px;
  min-width: 177px;
  width: 100%;
  ${breakpoint('tablet')`
max-width: 177px
  `}
`;
export const ConfirmModal = ({
  title,
  description,
  confirmActionText = 'Yes',
  cancelActionText = 'Cancel',
  trackingPrefix,
  isOpen,
  onCancel,
  onConfirm,
}: ConfirmModalProps): JSX.Element => {
  const primaryAction = (
    <StyledPrimaryButton trackingId={`${trackingPrefix}-cancel`} onClick={onCancel}>
      {cancelActionText}
    </StyledPrimaryButton>
  );
  const secondaryAction = (
    <StyledSecondary trackingId={`${trackingPrefix}-confirm`} onClick={onConfirm}>
      {confirmActionText}
    </StyledSecondary>
  );
  const reactModalProps: ReactModalProps = {
    isOpen,
    onRequestClose: onCancel,
  };
  return (
    <BaseModal
      title={title}
      primaryAction={primaryAction}
      secondaryAction={secondaryAction}
      reactModalProps={reactModalProps}
    >
      {description}
    </BaseModal>
  );
};

type HookConfirmModalProps = Omit<ConfirmModalProps, 'isOpen'>;
type ShowConfirmModal = (props: HookConfirmModalProps) => void;
type HideConfirmModal = () => void;

export const useConfirmModal = (): [ShowConfirmModal, HideConfirmModal] => {
  const [confirmModalProps, setConfirmModalProps] = useState<ConfirmModalProps>({
    isOpen: true,
    title: '',
    trackingPrefix: '',
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onCancel: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onConfirm: () => {},
  });

  const [showModal, hideModal] = useModal(() => <ConfirmModal {...confirmModalProps} isOpen />, [confirmModalProps]);

  const showConfirmModal: ShowConfirmModal = (props) => {
    const onCancel = () => {
      props.onCancel();
      hideModal();
    };
    const onConfirm = () => {
      props.onConfirm();
      hideModal();
    };
    const newConfirmModalProps: ConfirmModalProps = { ...props, onCancel, onConfirm, isOpen: true };
    setConfirmModalProps(newConfirmModalProps);
    showModal();
  };

  return [showConfirmModal, hideModal];
};
