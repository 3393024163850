import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { breakpoint, map } from 'styled-components-breakpoint';
import { Padding } from 'styled-components-spacing';
import { ReactComponent as Arrow } from '../../../svg/accordion-icon.svg';
import { ReactComponent as ApprovedIcon } from '../../../svg/approved.svg';
import { ReactComponent as Exclamation } from '../../../svg/info-circle.svg';
import { ReactComponent as UserIcon } from '../../../svg/user.svg';
import { theme } from '../../../theme';
import { colorRed, colorWhite } from '../../../theme.selectors';
import { Copy } from '../../Copy';
import { TaskUploads } from '../TaskUploads';
import { TaskProps } from './props';
import { TaskStatus, Eta } from './TaskStatus';
import { hasImportantInformation, shouldShowDropdown } from './util';

const StyledCopy = css`
  font-size: 14px;
  line-height: 16px;
  ${breakpoint('tablet')`
  font-size: 16px;
  line-height: 19px;
  `}
`;
const LeftIconSizing = css`
  ${breakpoint('mobile')`
  width: 24px;
  height: 24px;

  `}
  ${breakpoint('desktop')`
  width: 40px;
  height: 40px;
  `}
`;
const Container = styled.div<{ downloadIsOpen: boolean }>`
  background-color: ${colorWhite};
  border-width: 1px 0;
  border-style: solid;
  border-color: #d9d9d7;
  box-shadow: ${({ downloadIsOpen }): string => (downloadIsOpen ? ' 0 8px 16px 0 rgba(0, 0, 0, 0.1)' : 'none')};
  margin: 0 -16px;
  ${breakpoint('tablet')`
    margin: 0;
    border-width: 1px;
    border-radius: 4px;
    `}
`;

const TaskContainer = styled(Padding as React.ComponentType<any>)`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  z-index: 2;
`;

const DownloadDisplayContainer = styled.span<{ collapse: boolean }>`
  ${({ collapse }) => (collapse ? 'display: block;' : 'display: none;')}
`;

const StyledExclamation = styled(Exclamation as React.ComponentType<any>)`
  width: 20px;
  height: 20px;
  margin-right: 5px;
  margin-bottom: 5px;
`;
const StyledUser = styled(UserIcon as React.ComponentType<any>)`
  ${LeftIconSizing}
`;
const StyledApproved = styled(ApprovedIcon as React.ComponentType<any>)`
  ${LeftIconSizing}
`;

const TaskTitle = styled(Copy as React.ComponentType<any>)`
  margin: 0;
  display: flex;
  align-items: center;
  font-weight: 500;
  padding-right: 10px;
  ${breakpoint('mobile')`
    font-size: 18px;
    line-height: 22px;
  `}
  ${breakpoint('tablet')`
    font-size: 22px;
    line-height: 25px;
  `}
  ${breakpoint('desktop')`
    font-size: 24px;
    line-height: 28px;
  `}
`;
const LeftDiv = styled.div<{ status: string; collapse: boolean }>`
  min-height: 135px;
  border-radius: 3px 0 0 3px;
  background-color: ${({ status, theme }): string =>
    'complete' === status ? theme.colours.black : theme.colours.extraLightGray};
  width: 87px;
  ${breakpoint('tablet')`
    min-height: 107px;
  `}
`;
const LeftDivIcon = styled.div<{ status: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 135px;
  & > svg {
    fill: ${({ status, theme }): string => ('complete' === status ? theme.colours.white : theme.colours.black)};
  }
  & > svg g {
    fill: ${({ status, theme }): string => ('complete' === status ? theme.colours.white : theme.colours.black)};
  }
`;
const DetailDiv = styled.div`
  ${breakpoint('tablet')`
    overflow: hidden;
  `}
  width: 100%;
`;
const RightDiv = styled(Padding as React.ComponentType<any>)`
  min-height: 135px;
  width: 100%;
  overflow: hidden;

  ${breakpoint('tablet')`
    min-height: 107px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `}
  ${breakpoint(`mobile`)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  `};
`;

const TagEtaContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  row-gap: 3px;
  column-gap: 8px;
  ${breakpoint('mobile', 'tablet')`
    flex-direction: row;
  `}
`;

// zIndex required so that the description text renders underneath
const StatusContainer = styled(Padding as React.ComponentType<any>)`
  background: white;
  z-index: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  ${breakpoint('mobile', 'tablet')`
    width: 100%;
    height: auto;
    flex-direction: row;
  `}

  ${({ isCompleted }: { isCompleted: boolean }) =>
    !isCompleted ? 'justify-content: start;' : 'justify-content: center;'}
`;

const overflowHeight = 20;
const showMoreHeight = 90;
const TaskDescription = styled.div<{ expand: boolean; hasShowMore: boolean }>`
  opacity: 0.7;
  margin-top: 0.5rem;
  overflow: visible;
  ${map(theme.fontSizes.standard, (val) => `font-size: ${val}px;`)};
  ${map(theme.lineHeights.standard, (val) => `line-height: ${val};`)};
  ${({ expand, hasShowMore }) =>
    !expand ? `max-height: ${overflowHeight + (hasShowMore ? 0 : showMoreHeight)}px` : ''};
`;

const StyledMarkdown = styled.div`
  min-height: ${overflowHeight}px;
`;

const ShowMore = styled.div<{ expand: boolean; isVisible: boolean }>`
  overflow: ${({ expand }) => (expand ? 'auto' : 'hidden')};
  height: ${showMoreHeight}px;
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
  align-items: end;
  font-size: 15px;
  color: ${({ theme }) => theme.colours.blue};
  position: relative;
  background: transparent;
  background-image: ${({ expand }) =>
    expand ? 'rgba(255, 0, 255, 0)' : 'linear-gradient(to bottom,rgba(255,255,0,0) 5%, rgba(255,255,255,1) 70%)'};
`;

const Error = styled.div`
  color: ${colorRed};
  margin-top: 20px;
  ${StyledCopy}
`;

const DropDownDiv = styled.div`
  flex: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: end;
`;

// eslint-disable-next-line
const StyledArrow = styled(Arrow)<{ direction: 'up' | 'down' }>`
  cursor: pointer;
  transform: rotate(${({ direction }): number => (direction === 'up' ? 180 : 0)}deg);
`;

const StyledReadMoreArrow = styled(StyledArrow as React.ComponentType<any>)`
  width: 20px;
  height: 20px;
`;

const StyledShowMore = styled.span`
  cursor: pointer;
`;

export const ActionTask = (task: TaskProps): JSX.Element => {
  const {
    projectId,
    id,
    title,
    shortDescription,
    status,
    payment,
    due,
    warning,
    error,
    assignedTo = 'user',
    projectStatus,
    files = [],
    setProject,
  } = task;

  const isCompleted = status === 'COMPLETE';
  const showDropDown = shouldShowDropdown(task);
  const [collapse, toggleCollapse] = useState<boolean>(hasImportantInformation(task));
  const [rfiCollapse, toggleRfiCollapse] = useState<boolean>(false);
  const [hasShowMore, setHasShowMore] = useState(false);

  const rfiWrapper = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    let heightOfWrapper = 0;
    if (rfiWrapper.current !== null) {
      heightOfWrapper = rfiWrapper.current.offsetHeight as number;
    }
    setHasShowMore(heightOfWrapper > overflowHeight + showMoreHeight);
  }, [rfiWrapper, window.innerWidth]);

  const handleDropDownKeyPress = (e: React.KeyboardEvent) => {
    e.preventDefault();
    if (e.key == 'Enter') {
      toggleCollapse(!collapse);
    }
  };

  let icon = <StyledApproved />;
  if ('approved' !== assignedTo?.toLowerCase()) {
    icon = <StyledUser />;
  }

  const taskDescription = warning?.length ? warning : shortDescription;
  const canUpload = !isCompleted && projectStatus !== 'completed';
  return (
    <Container downloadIsOpen={collapse}>
      <TaskContainer>
        <LeftDiv collapse={rfiCollapse} status={status.toLowerCase()}>
          <LeftDivIcon status={status.toLowerCase()}>{icon}</LeftDivIcon>
        </LeftDiv>
        <RightDiv all={{ mobile: 3, tablet: 4, desktop: 5 }} bottom={{ mobile: 0 }}>
          <DetailDiv>
            <TaskTitle>{title}</TaskTitle>
            {taskDescription && (
              <>
                {status !== 'COMPLETE' && (
                  <TaskDescription expand={rfiCollapse} hasShowMore={hasShowMore}>
                    <span ref={rfiWrapper}>
                      <StyledMarkdown dangerouslySetInnerHTML={{ __html: taskDescription ?? '' }} />
                    </span>
                  </TaskDescription>
                )}

                <ShowMore
                  isVisible={hasShowMore}
                  onClick={() => {
                    toggleRfiCollapse(!rfiCollapse);
                  }}
                  expand={rfiCollapse}
                >
                  <span>
                    <StyledShowMore>{rfiCollapse ? 'Show less ' : 'Show more '}</StyledShowMore>
                    <StyledReadMoreArrow
                      tabIndex={0}
                      onKeyPress={() => toggleRfiCollapse(!rfiCollapse)}
                      direction={rfiCollapse ? 'up' : 'down'}
                    />
                  </span>
                </ShowMore>
              </>
            )}
            {error && (
              <Error>
                <StyledExclamation />
                {error}
              </Error>
            )}
          </DetailDiv>
          <StatusContainer
            top={{ mobile: 3, tablet: 0, desktop: 0 }}
            bottom={{ mobile: 3, tablet: 0, desktop: 0 }}
            isCompleted={isCompleted}
          >
            <TagEtaContainer>
              <TaskStatus
                status={status}
                payment={payment}
                assignedTo={assignedTo}
                warning={warning}
                files={files}
                id={id}
              />
              {due && status !== 'COMPLETE' && status !== 'CANCELLED' && (
                <Eta status={status} payment={payment} assignedTo={assignedTo} due={due} />
              )}
            </TagEtaContainer>
            {showDropDown ? (
              <DropDownDiv
                onClick={() => {
                  toggleCollapse(!collapse);
                }}
                aria-expanded={collapse}
              >
                <StyledArrow tabIndex={0} onKeyPress={handleDropDownKeyPress} direction={collapse ? 'up' : 'down'} />
              </DropDownDiv>
            ) : null}
          </StatusContainer>
        </RightDiv>
      </TaskContainer>

      {/* 'display: none' is used instead of returning null so that the state
      can still update as the component remains mounted while the file uploads.*/}
      <DownloadDisplayContainer collapse={collapse}>
        <TaskUploads files={files} projectId={projectId} taskId={id} setProject={setProject} canUpload={canUpload} />
      </DownloadDisplayContainer>
    </Container>
  );
};
