/* eslint-disable */
import React from 'react';

export type BaseLinkProps = {
  trackingId?: string;

  onClick?: (event: React.MouseEvent<any, any>) => void;
};

export const buildBaseLink =
  <T extends BaseLinkProps>(LinkComponent: React.ComponentType<Omit<T, 'trackingId'>>) =>
  ({ trackingId, ...rest }: T): JSX.Element => {
    const onClick = rest.onClick;

    const handleClick = (event: React.MouseEvent<any, any>) => {
      onClick && onClick(event);
    };
    return <LinkComponent {...rest} onClick={handleClick} />;
  };
