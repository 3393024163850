import React, { useEffect } from 'react';
import { AxiosError } from 'axios';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { useApiRequest } from '../../../hooks/useApiRequest';
import { Project, Quote } from '../../../services/openapi.types.generated';
import { PrimaryButton } from '../../Button/PrimaryButton';

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const StyledButton = styled(PrimaryButton as React.ComponentType<any>)`
  width: 100%;
  ${breakpoint('tablet')`
    height: 48px;
    width: 225px;
`}
`;

type InvoiceProps = {
  subjectId: string;
  setLoading: (loading: boolean) => void;
  setError: (error: AxiosError | undefined) => void;
  setData: (data: Project | Quote) => void;
  validFrom?: string;
};

/**
 * This section will show an invoice button
 * When clicked - will make sure that the user is logged in
 *  & will enure that the user has authorization to invoice for this task
 * @param InvoiceProps
 * @returns
 */
export const InvoiceButton: React.FC<InvoiceProps> = ({
  setLoading,
  setError,
  setData,
  subjectId,
  validFrom,
  ...props
}): JSX.Element => {
  const [{ data, loading, error }, executePost] = useApiRequest<Project>(
    {
      method: 'post',
      url: `/payment/quotes/${subjectId}/invoice`,
    },
    { manual: true, authRedirect: false },
  );

  useEffect(() => {
    setLoading(loading);
    if (error) setError(error);
    if (data) setData(data);
  }, [data, error, loading]);

  return (
    <ButtonWrapper {...props} id="feeProposal">
      <StyledButton trackingId={'invoice-tasks'} onClick={() => executePost({ data: { validFrom: validFrom } })}>
        {'Accept Fee Proposal'}
      </StyledButton>
      <div style={{ fontSize: '14px' }}>*Invoice will be processed soon</div>
    </ButtonWrapper>
  );
};
