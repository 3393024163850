import styled from 'styled-components';
import { BaseButton, BaseButtonProps } from '../BaseButton';
import { colorBlack, colorBlue, colorWhite, outlineBlue } from '../../../theme.selectors';

export type HeaderButtonProps = BaseButtonProps;

export const HeaderButton = styled(BaseButton as React.ComponentType<any>)`
  height: 38px;
  width: auto;
  padding: 0 12px;
  color: ${colorWhite};
  background-color: ${colorBlack};
  border: 2px solid ${colorWhite};
  border-radius: 28px;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
    color: ${colorWhite};
    background-color: ${colorBlack};
    border: 2px solid ${colorWhite};
    outline: none;
  }

  &:focus {
    color: ${colorBlack};
    background-color: ${colorWhite};
    border: 2px solid ${colorBlack};
    outline: 2px solid ${colorBlue};
  }
  &:active:disabled {
    outline: none;
  }
  &:active:enabled {
    opacity: 0.5;
    color: ${colorBlack};
    background-color: ${colorWhite};
    border-color: ${colorWhite};
    outline: 2px solid ${outlineBlue};
  }
`;
