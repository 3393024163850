import React, { useCallback, useEffect, useState } from 'react';
import { useContext } from 'react';
import styled from 'styled-components';
import { breakpoint } from 'styled-components-breakpoint';
import { Notification } from '../../../common/notifications';
import { useApiRequest } from '../../../hooks/useApiRequest';
import { RecaptchaContext } from '../../../hooks/useRecaptcha';
import { Milestone, Project, Task } from '../../../services/openapi.types.generated';
import { FileListItem, FileUpload } from '../../FormControls/FileUpload/FileUpload';

const Container = styled.div`
  ${breakpoint('tablet')`
    text-align: right;
  `}
`;

const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.colours.red};
  margin: 4px 0;
`;

type FileUploadProps = {
  projectId: string;
  taskId: string;
  setProject?: (project: Project) => void;
  executeRecaptcha: () => Promise<string | null>;
};

const sendUploadNotification = () => {
  Notification(
    'success',
    <span style={{ color: 'white', fontWeight: 'bold' }}>Document Received</span>,
    <>
      <p>The document will be reviewed shortly. If necessary, you can upload further supporting documents.</p>
    </>,
  );
};

export const ProjectFileUpload = ({ projectId, taskId, setProject }: FileUploadProps): JSX.Element => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const { getToken } = useContext(RecaptchaContext);

  const [{ data: projectResponse, error: uploadError }, uploadFiles] = useApiRequest<Project>(
    {
      url: `/projects/${projectId}/task/${taskId}/file`,
      method: 'post',
      data: { files: [], recaptcha: '' },
    },
    { manual: true, authRedirect: true },
  );

  useEffect(() => {
    if (setProject && projectResponse) {
      setProject(projectResponse);
      sendUploadNotification();
    }
  }, [setProject, projectResponse]);

  useEffect(() => {
    if (uploadError) {
      console.error(uploadError);
      setErrorMessage(uploadError.response?.data?.error ?? 'Error uploading files');
    }
  }, [uploadError, setErrorMessage]);

  const handleFileUpload = useCallback(async (fileList: FileListItem[]) => {
    console.log('handleFileUpload', fileList);
    const fileIdList = fileList.map((file) => file.id);

    console.log(fileIdList);

    console.log('About to fetch the recaptcha token');
    const reCaptchaToken = await getToken();

    console.log('recaptchaToken', reCaptchaToken);

    await uploadFiles({
      data: {
        files: fileIdList,
        recaptcha: reCaptchaToken,
      },
    });
  }, []);

  return (
    <Container>
      <FileUpload
        updateProjectForFiles={handleFileUpload}
        setErrorMessage={setErrorMessage}
        onLoadingChanged={() => {
          return;
        }}
        existingFiles={
          projectResponse?.milestones
            ?.flatMap((m: Milestone) => m?.tasks ?? [])
            .find((task: Task) => task?.id === taskId)?.files
        }
      />
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </Container>
  );
};
