import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { breakpoint } from 'styled-components-breakpoint';
import { useDebouncedCallback } from 'use-debounce';
import { DashboardGrid, Heading, Layout, Pagination } from '../../components';
import { PageObject } from '../../components/Pagination/types';
import { useLoading } from '../../context/LoadingContext';
import { useApiRequest, useErrorRedirect } from '../../hooks/useApiRequest';
import Desktop1xJpg from '../../images/quote-form-hero/desktop-1x.jpg';
import Desktop2xJpg from '../../images/quote-form-hero/desktop-2x.jpg';
import Mobile1xJpg from '../../images/quote-form-hero/mobile-1x.jpg';
import Mobile2xJpg from '../../images/quote-form-hero/mobile-2x.jpg';
import Tablet1xJpg from '../../images/quote-form-hero/tablet-1x.jpg';
import Tablet2xJpg from '../../images/quote-form-hero/tablet-2x.jpg';

import { Project } from '../../services/openapi.types.generated';
import { ReactComponent as ApprovedIcon } from '../../svg/approved.svg';
import { bodyFamily } from '../../theme.selectors';
import { useDashboardForm } from './useDashboardForm';

const FlexDiv = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  position: relative;
  bottom: 20%;
  ${breakpoint('tablet')`
      bottom: 50%;
  `}
  ${breakpoint('desktop')`
      bottom: 20%;
  `}
  justify-content: center;
  align-items: center;
  text-align: center;
`;
const WhiteHeading1 = styled(Heading.H1 as React.ComponentType<any>)`
  color: white;
  margin-bottom: 8px;
`;
const WhiteHeading2 = styled(Heading.H2 as React.ComponentType<any>)`
  font-size: 14px;
  font-family: ${bodyFamily};
  color: white;
  line-height: 18px;
  font-weight: 500;
  margin-top: 8px;

  ${breakpoint('tablet')`
    font-size: 18px;
    margin-top: 14px;
  `}
  ${breakpoint('desktop')`
    font-size: 24px;
    line-height: 24px;
    margin-top: 8px;
  `}
`;
const StyledApprovedIcon = styled(ApprovedIcon as React.ComponentType<any>)`
  color: white;
  margin-bottom: 8px;
  width: 48px;
  height: 48px;
  ${breakpoint('desktop')`
  margin-bottom: 16px;
  width: 88px;
  height: 88px;
  `}
`;

const DashboardContainer = styled.div``;

const DefaultHeading = (
  <FlexDiv>
    <StyledApprovedIcon />
    <WhiteHeading1>Your Projects</WhiteHeading1>
    <WhiteHeading2>Smarter, faster approvals</WhiteHeading2>
  </FlexDiv>
);

type ProjectsResponse = { value: Project[]; total: number; pageSize: number };

const Dashboard = (): JSX.Element => {
  const [currentPage, setCurrentPage] = useState(0);
  const [sortOrder, setSortOrder] = useState('desc');
  const [showFilter, setShowFilter] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const { loading, setLoading } = useLoading();

  const [params, updateParam] = useDashboardForm();

  const [{ data: projectsData, loading: projectsLoading, error: projectsError }, fetchProjects] =
    useApiRequest<ProjectsResponse>('/projects', {
      authRedirect: true,
      manual: true,
    });

  const debounceFetchProjects = useDebouncedCallback((page: number) => {
    setCurrentPage(page);
    fetchProjects({
      params: {
        page,
        ...params,
      },
    });
    setInitialLoading(false);
  }, 100);

  useEffect(() => setLoading(true), []);
  useEffect(() => setLoading(projectsLoading || initialLoading), [initialLoading, projectsLoading]);

  useEffect(() => {
    debounceFetchProjects(0);
  }, [params, debounceFetchProjects]);

  useErrorRedirect([projectsError]);

  const handlePageChange = useCallback(
    (page: PageObject) => {
      debounceFetchProjects(page.selected);
    },
    [debounceFetchProjects],
  );

  const pageCount = (projectsData && Math.ceil(projectsData.total / projectsData.pageSize)) ?? 0;

  return (
    <Layout
      mobile={Mobile1xJpg}
      mobile2x={Mobile2xJpg}
      tablet={Tablet1xJpg}
      tablet2x={Tablet2xJpg}
      desktop={Desktop1xJpg}
      desktop2x={Desktop2xJpg}
      heading={DefaultHeading}
      showBackButton={false}
      burgerMenuActive={true}
    >
      <DashboardContainer>
        <DashboardGrid
          projectsData={projectsData?.value ?? []}
          sortBy={params.sort}
          setSortBy={updateParam('sort')}
          sortOrder={sortOrder}
          setSortOrder={setSortOrder}
          showFilter={showFilter}
          setShowFilter={setShowFilter}
          idText={params.applicationId}
          setIdText={updateParam('applicationId')}
          addressText={params.address}
          setAddressText={updateParam('address')}
          clientReferenceText={params.clientReference}
          setClientReferenceText={updateParam('clientReference')}
          filterStatus={params.status}
          setFilterStatus={updateParam('status')}
        />
        <br />
        {pageCount > 1 && !loading && projectsData?.value?.length && (
          <Pagination currentPage={currentPage} pageCount={pageCount} onPageChange={handlePageChange} />
        )}
      </DashboardContainer>
    </Layout>
  );
};

export const DashboardPage = Dashboard;
