import React from 'react';
import { ReCAPTCHA } from 'react-google-recaptcha';
import styled from 'styled-components';
import { Margin } from 'styled-components-spacing';
import { useReCaptcha } from '../../../hooks/useRecaptcha';
import { Task as TaskProps, Project, ProjectStatus } from '../../../services/openapi.types.generated';
import { reCaptchaSiteKey } from '../../Form/constants';
import { ActionTask } from '../ActionTask';

export type TaskListProps = {
  tasks?: Array<TaskProps>;
  projectId: string;
  projectStatus: ProjectStatus;
  setProject?: (project: Project) => void;
};

export const TaskList = ({ tasks = [], projectId, setProject, projectStatus }: TaskListProps): JSX.Element => {
  return (
    <>
      {tasks.map((task: TaskProps) => {
        const errorMessage = ('error' === task.messageType && task.message) || '';
        const warningMessage = ('warning' === task.messageType && task.message) || '';
        let shownDate = '';
        if (task.status === 'COMPLETE' && task.completedDate) {
          shownDate = task.completedDate;
        } else if (task.estimatedDueDate) {
          shownDate = task.estimatedDueDate;
        }
        return (
          <Margin bottom={4} key={task.id}>
            <ActionTask
              {...task}
              projectId={projectId}
              warning={warningMessage}
              error={errorMessage}
              due={shownDate}
              projectStatus={projectStatus}
              setProject={setProject}
            />
          </Margin>
        );
      })}{' '}
    </>
  );
};
