import React from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { StyledSelect } from '../../FormControls';
import { ReactComponent as ChevronDown } from '../../../svg/chevron-down.svg';
import { StatusFilters } from '../Filters/types';
import { Filters } from '../Filters';

const GridSection = styled.div`
  display: block;
  width: 100%;

  ${breakpoint('desktop')`
    display: none;
  `}
`;

const FilterChevron = styled(ChevronDown as React.ComponentType<any>)`
  width: 18px;
  height: 18px;
`;
const UpsideDownFilterChevron = styled(FilterChevron as React.ComponentType<any>)`
  transform: rotate(180deg);
`;
const FilterSection = styled.div``;

const StyledFlexDiv = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  justify-content: flex-end;
`;
const SortSelect = styled(StyledSelect as React.ComponentType<any>)`
  margin-right: 1.5em;
  max-width: none;
  width: 100%;
  max-width: 185px;
  ${breakpoint('tablet')`
max-width: 234px;
`}
`;

const StyledChevron = styled(ChevronDown as React.ComponentType<any>)`
  margin-left: -3em;
  pointer-events: none;
  position: relative;
  left: -1em;
`;
const ShowFilterDiv = styled.div`
  text-decoration: underline;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 20px;
  margin-right: 8px;
`;
const FilterLinkSection = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 16px;
`;
const FilterButton = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  &:hover {
    opacity: 0.8;
  }
`;

export type Props = {
  sortBy: string;
  sortOrder: string;
  showFilter?: boolean;
  setSortBy: (sort: string) => void;
  setShowFilter?: (show: boolean) => void;
  setSortOrder: (sort: string) => void;
  idText: string;
  setIdText: (id: string) => void;
  addressText: string;
  setAddressText: (address: string) => void;
  clientReferenceText: string;
  setClientReferenceText: (reference: string) => void;
  filterStatus: string;
  setFilterStatus: (status: StatusFilters) => void;
};

export const ProjectFiltersSection: React.FC<Props> = ({
  sortBy,
  idText,
  setIdText,
  addressText,
  setAddressText,
  clientReferenceText,
  setClientReferenceText,
  filterStatus,
  setFilterStatus,
  showFilter,
  setShowFilter,
  setSortOrder,
  setSortBy,
}: Props) => {
  const options = [
    { label: 'Project', value: 'applicationId' },
    { label: 'Project', value: '-applicationId' },
  ];
  const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newSort = e.target.value;
    setSortBy(newSort);
    if (newSort.indexOf('-') > -1) {
      setSortOrder('desc');
    } else {
      setSortOrder('asc');
    }
  };

  return (
    <GridSection>
      {!showFilter && setShowFilter && (
        <FilterLinkSection>
          <FilterButton
            tabIndex={0}
            onKeyPress={(e: React.KeyboardEvent) => {
              e.preventDefault();
              if (e.key == 'Enter') {
                setShowFilter(true);
              }
            }}
            onClick={() => setShowFilter(true)}
          >
            {' '}
            <ShowFilterDiv>Show Filters</ShowFilterDiv>
            <FilterChevron />
          </FilterButton>
        </FilterLinkSection>
      )}
      {showFilter && setShowFilter && (
        <FilterLinkSection>
          <FilterButton
            tabIndex={0}
            onKeyPress={(e: React.KeyboardEvent) => {
              e.preventDefault();
              if (e.key == 'Enter') {
                setShowFilter(false);
              }
            }}
            onClick={() => setShowFilter(false)}
          >
            {' '}
            <ShowFilterDiv>Hide Filters</ShowFilterDiv>
            <UpsideDownFilterChevron />
          </FilterButton>
        </FilterLinkSection>
      )}
      {showFilter && (
        <FilterSection>
          {' '}
          <Filters
            filterStatus={filterStatus}
            setFilterStatus={setFilterStatus}
            clientReferenceText={clientReferenceText}
            setClientReferenceText={setClientReferenceText}
            idText={idText}
            setIdText={setIdText}
            addressText={addressText}
            setAddressText={setAddressText}
          />
          <StyledFlexDiv>
            <SortSelect error={false} onChange={onChange} value={sortBy}>
              <option value="" disabled>
                Sort by
              </option>
              {options.map((option) => {
                if (option.value.indexOf('-') > -1) {
                  return (
                    <option key={option.value} value={option.value}>
                      {option.label} &#9660;
                    </option>
                  );
                } else {
                  return (
                    <option key={option.value} value={option.value}>
                      {option.label} &#9650;
                    </option>
                  );
                }
              })}
            </SortSelect>
            <StyledChevron />
          </StyledFlexDiv>
        </FilterSection>
      )}
    </GridSection>
  );
};
