import { Skeleton } from 'antd';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { borderColor, colorWhite } from '../../theme.selectors';
import { Filters } from './Filters';

export const tableColumnProps = {
  application: { width: '55', style: { maxWidth: '55px' } },
  reference: { width: '70', style: { maxWidth: '70px' } },
  address: { width: '170', style: { maxWidth: '170px' } },
  pathway: { width: '130', style: { maxWidth: '130px' } },
  completionDate: { width: '80', style: { maxWidth: '80px' } },
  status: { width: '110', style: { minWidth: '110px' } },
};

export const StyledFilters = styled(Filters as React.ComponentType<any>)`
  margin-bottom: 46px;
`;

// TableSection min height prevents height bounce from skele to content load
// and adds permanent scroll on loaded state to prevent width bounce from skele to content load
export const TableSection = styled.div`
  min-height: 400px;
  display: none;
  ${breakpoint('desktop')`
    display: block;
  `}
`;

export const GridSection = styled.div`
  display: block;
  width: 100%;
  ${breakpoint('desktop')`
    display: none;
  `}
`;

// remove margin from skeleton paragraph ul to vertically center within row
export const StyledSkeleton = styled(Skeleton as React.ComponentType<any>)`
  ul {
    margin-bottom: 0;
  }
`;

export const StyledTable = styled.table`
  width: 100%;
  display: none;
  ${breakpoint('desktop')`
    display: table;
  `}
  .fad {
    margin-left: 10px;
  }
`;

export const StyledStrong = styled.strong`
  font-weight: 500;
  text-transform: capitalize;
`;

export const Tbody = styled.tbody``;

export const Thead = styled.thead``;

export const Tr = styled.tr`
  border: 1px solid ${borderColor};
  border-bottom: 0px;
  background-color: ${colorWhite};
  word-break: normal;
  ${({ disabled }: { disabled: boolean }) => `cursor: ${disabled ? 'not-allowed' : 'pointer'}`};
  &:hover {
    ${({ disabled }: { disabled: boolean }) => `opacity: ${disabled ? '1' : '0.6'}`};
  }
  &:last-of-type {
    border-bottom: 1px solid ${borderColor};
  }
`;

export const Th = styled.th`
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 28px;
  word-break: keep-all;
  text-align: left;
  white-space: pre;
  padding-left: 8px;
`;

export const HeadTr = styled.tr`
  text-align: left;
  word-break: normal;
`;

export const Td = styled.td`
  height: 44px;
  word-break: keep-all;
  font-size: 16px;
  text-align: left;
  padding-left: 8px;
`;

export const EllipsTd = styled(Td as React.ComponentType<any>)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const SortHeader = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;
