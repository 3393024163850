/**
 * A message that can appear on the screen that is in the styling of an action task
 */

import React from 'react';
import styled, { css } from 'styled-components';
import { breakpoint } from 'styled-components-breakpoint';
import { Padding } from 'styled-components-spacing';
import { ReactComponent as Exclamation } from '../../../svg/info-circle.svg';
import { Copy } from '../../Copy';

type TaskProps = {
  title: string;
  description: string;
};

const StyledCopy = css`
  font-size: 14px;
  line-height: 16px;
  ${breakpoint('tablet')`
  font-size: 16px;
  line-height: 19px;
  `}
`;

const TaskContainer = styled(Padding as React.ComponentType<any>)`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: right;
  z-index: 2;
  border: 1px solid #000000;
  border-radius: 4px;
`;

const Description = styled.div`
  ${StyledCopy}
  font-size: 14px;
  margin-top: 10px;

  ${breakpoint('desktop')`
    font-size: 18px;
    line-height: 21px;
  `}
`;
const StyledExclamation = styled(Exclamation as React.ComponentType<any>)`
  width: 20px;
  height: 20px;
  ${breakpoint('tablet')`
  width: 24px;
  height: 24px;

  `}
  ${breakpoint('desktop')`
  width: 40px;
  height: 40px;
  `}
`;

const StyledStrong = styled.strong`
  font-weight: 500;
`;
const BiggerCopy = styled(Copy as React.ComponentType<any>)`
  margin: 0;
  display: flex;
  align-items: center;
  ${breakpoint('mobile')`
    font-size: 18px;
    line-height: 22px;
  `}
  ${breakpoint('tablet')`
    font-size: 22px;
    line-height: 25px;
  `}
  ${breakpoint('desktop')`
    font-size: 24px;
    line-height: 28px;
  `}
`;
const LeftDiv = styled.div`
  flex: 1 1 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10%;
  min-height: 135px;
  background-color: black;
  width: 87px;
  & > svg {
    fill: white;
  }
  & > svg g {
    fill: white;
  }
  ${breakpoint('tablet')`
    min-height: 107px;
  `}
`;

const RightDiv = styled(Padding as React.ComponentType<any>)`
  flex: 1 1 90%;
  min-height: 135px;
  flex-direction: column;
  ${breakpoint('tablet')`
    display: flex;
    min-height: 107px;
  `}
`;

export const MessageTask = ({ title, description }: TaskProps): JSX.Element => {
  return (
    <TaskContainer>
      <LeftDiv>
        <StyledExclamation />
      </LeftDiv>
      <RightDiv all={{ mobile: 4, tablet: 5, desktop: 6 }}>
        <BiggerCopy>
          <StyledStrong>{title}</StyledStrong>
        </BiggerCopy>
        <Description>{description}</Description>
      </RightDiv>
    </TaskContainer>
  );
};
