import React from 'react';
import styled from 'styled-components';
import { breakpoint } from 'styled-components-breakpoint';
import { ReactComponent as ChevronDown } from '../../../svg/chevron-down.svg';
import { StyledInput } from '../../FormControls/StyledInput';
import { StyledSelect } from '../../FormControls/StyledSelect';
import { Heading } from '../../Heading/Heading';
import { StatusFilters, statusOptions } from './types';
type filterProps = {
  className?: string;
  idText: string;
  setIdText: (id: string) => void;
  clientReferenceText: string;
  setClientReferenceText: (id: string) => void;
  addressText: string;
  setAddressText: (address: string) => void;
  filterStatus: string;
  setFilterStatus: (status: StatusFilters) => void;
};
const StyledFlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const FilterSelect = styled(StyledSelect as React.ComponentType<any>)`
  width: 100%;
  max-width: none;
`;

const StyledFilteredChevron = styled(ChevronDown as React.ComponentType<any>)`
  pointer-events: none;
  position: relative;
  left: -1em;
  margin-left: -1.5em;
`;
const InputSection = styled.div`
  margin-bottom: 16px;
  ${breakpoint('tablet')`
  margin-bottom: 0;
`}
`;
const StyledFilterRow = styled.div`
  display: flex;
  flex-direction: column;
  ${breakpoint('tablet')`
 flex-direction: row;
 margin-bottom: 24px;

`}
  & > div {
    width: 100%;
  }
  & > div:nth-child(1) {
    ${breakpoint('tablet')`
        max-width: 158px;
    `}
    ${breakpoint('desktop')`
        max-width: 404px;
`}
  }
  & > div:nth-child(2) {
    ${breakpoint('tablet')`
      margin-left: 32px;
      max-width: 267px;
  `}
    ${breakpoint('desktop')`
    margin-left: 32px;
    max-width: 402px;
  `}
  }
  & > div:nth-child(3) {
    ${breakpoint('tablet')`
        margin-left: 32px;
        margin-right: 32px;
        min-width: 272px;
    `}
    ${breakpoint('desktop')`
        margin-left: 32px;
        margin-right: 32px;
        max-width: 274px;
    `}
  }
`;
export const Filters = ({
  className,
  filterStatus,
  setFilterStatus,
  idText,
  setIdText,
  clientReferenceText,
  setClientReferenceText,
  addressText,
  setAddressText,
}: filterProps): JSX.Element => {
  return (
    <StyledFilterRow className={className}>
      <InputSection>
        {' '}
        <label htmlFor="approved-application-num">
          <Heading.H5>Project</Heading.H5>
        </label>
        <StyledFlexDiv>
          <StyledInput
            error={false}
            name="approved-application-num"
            placeholder="e.g. JB220001"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setIdText(e.target.value);
            }}
            value={idText}
          ></StyledInput>
        </StyledFlexDiv>
      </InputSection>
      <InputSection>
        {' '}
        <label htmlFor="client-reference">
          <Heading.H5>Your Reference</Heading.H5>
        </label>
        <StyledFlexDiv>
          <StyledInput
            error={false}
            name="client-reference"
            placeholder="e.g. PO1234"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setClientReferenceText(e.target.value);
            }}
            value={clientReferenceText}
          ></StyledInput>
        </StyledFlexDiv>
      </InputSection>
      <InputSection>
        {' '}
        <label htmlFor="address">
          <Heading.H5>Site Address</Heading.H5>
        </label>
        <StyledFlexDiv>
          <StyledInput
            error={false}
            name="address"
            placeholder="e.g. 104 Hannell St"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setAddressText(e.target.value);
            }}
            value={addressText}
          ></StyledInput>
        </StyledFlexDiv>
      </InputSection>
      <InputSection>
        {' '}
        <label htmlFor="status">
          <Heading.H5>Status</Heading.H5>
        </label>
        <StyledFlexDiv>
          <FilterSelect
            name="status"
            error={false}
            value={filterStatus}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setFilterStatus(e.target.value as StatusFilters);
            }}
          >
            {statusOptions.map((option) => {
              return (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              );
            })}
          </FilterSelect>
          <StyledFilteredChevron />
        </StyledFlexDiv>
      </InputSection>
    </StyledFilterRow>
  );
};
