import React from 'react';
import styled from 'styled-components';
import { bodyFamily } from '../../../theme.selectors';
import { Copy } from '../../Copy';
import { Heading } from '../../Heading/Heading';

type ProjectProps = {
  projectId?: string;
  projectName?: string;
};
const FlexDiv = styled.div`
  color: white;
`;
const ProjectName = styled(Heading.H1 as React.ComponentType<any>)`
  color: white;
`;
const StyledCopy = styled(Copy as React.ComponentType<any>)`
  font-weight: normal;
  font-family: ${bodyFamily};
`;
export const ProjectTitle = ({ projectId, projectName }: ProjectProps): JSX.Element => {
  return (
    <FlexDiv>
      {projectId && <StyledCopy>Project: {projectId}</StyledCopy>}
      {projectName && <ProjectName>{projectName}</ProjectName>}
    </FlexDiv>
  );
};
