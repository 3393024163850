import { InteractionStatus } from '@azure/msal-browser';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, Menu, MenuItem, Wrapper } from 'react-aria-menubutton';
import { ReactComponent as PhoneIcon } from '../../svg/phone.svg';
import styled from 'styled-components';
import { Link as StyleLink } from '../Link/Link';
import { breakpoint } from 'styled-components-breakpoint';
import { px, py } from 'styled-components-spacing';
import { ReactComponent as Exclamation } from '../../svg/circle-exclamation.svg';
import { ReactComponent as PersonImage } from '../../svg/user-circle.svg';
import { colorBlack, colorBlue, colorWhite, outlineBlue, primaryGray } from '../../theme.selectors';
import { Typography } from 'antd';
import { ApprovedPhoneNumber } from '../Form/constants';
import { authenticationRequest } from '../AuthenticatedRoute';
import { useLoading } from '../../context/LoadingContext';
import { useApiRequest } from '../../hooks/useApiRequest';
import { requestStatementFailed, requestStatementSucceeded } from '../Menu';
import { getLatestAccount } from '../../services/authentication';
import { BaseButton } from '../Button/BaseButton';
import { Divider } from '../Divider';
import { LoginBanner } from '../Banners';
import { Hero } from '../Hero';
import { LayoutContainer as ContentContainer } from '../ContentContainer';
import { BurgerMenu } from '../BurgerMenu';

type LayoutProps = {
  children: React.ReactNode;
  heading: React.ReactNode;
  mobile: string;
  mobile2x: string;
  tablet: string;
  tablet2x: string;
  desktop: string;
  desktop2x: string;
  showLoginBanner?: boolean;
  showBackButton?: boolean;
  burgerMenuActive?: boolean;
};
const StyledLink = styled.div`
  margin-left: 5px;
  text-decoration: underline;
  cursor: pointer;
`;
const LayoutContainer = styled.div`
  background-color: ${primaryGray};
  max-width: 2000px;
  margin: 0 auto;
`;
const StyledMenu = styled(Menu as React.ComponentType<any>)`
  position: absolute;
  background: ${colorWhite};
  color: ${colorBlack};
  z-index: 555;
  right: 0px;
  top: 50px;
  border: 1px solid rgba(200, 200, 200, 0.4);
  border-radius: 3px;
  ${breakpoint('desktop')`
     right: 0px;
  `}
`;
const MenuList = styled.ul`
  width: auto;
  list-style-type: none;
  top: 100%;
  left: 0;
  z-index: 99;
  padding-left: 0;
  margin: 0px;
  font-size: 14px;
  word-wrap: ${breakpoint('desktop')`
      width: 100px;
      font-size: 16px;
      margin: 0;
  `};
`;
const StyledPerson = styled(PersonImage as React.ComponentType<any>)`
  background-color: ${colorBlack};
  height: 40px;
  width: 40px;
  cursor: pointer;
`;
const Header = styled.header`
  background-color: ${colorBlack};
`;

const ContentWrapper = styled.div`
  ${px({ mobile: 4, tablet: 5 })}
`;
const Masthead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  ${py(4)};
  ${px({ mobile: 4, tablet: 5 })}
`;
const HeaderContainer = styled.div`
  position: relative;
  max-width: 1240px;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${breakpoint('tablet')`
    justify-content: space-between;
  `}
  ${breakpoint('mobile')`
    justify-content: flex-start;
  `}
`;
const Logo = styled.div`
  width: 153px;
  height: 36px;
  background-image: url('/logo.svg');
  background-repeat: no-repeat;

  ${breakpoint('tablet')`
    width: 203px;
    height: 48px;
  `}
`;
const ButtonWrapper = styled(Wrapper)<{ ['data-burger-menu-active']: boolean }>`
  position: relative;
  display: ${({ ['data-burger-menu-active']: burgerMenuActive }) => (!burgerMenuActive ? 'block' : 'none')};
  ${breakpoint('tablet')`
    display: block;`}
`;

const StyledExclamation = styled(Exclamation as React.ComponentType<any>)`
  height: 16px;
  width: 16px;
  margin-right: 8px;
`;
const StyledMenuItem = styled(MenuItem as React.ComponentType<any>)`
  cursor: pointer;
  padding: 5px;
  text-align: center;
  ${breakpoint('desktop')`
padding: 8px;
`}
  &:hover {
    opacity: 0.6;
  }
  &:focus {
    opacity: 0.6;
  }
`;
const StyledMenuItemWrapped = styled(MenuItem as React.ComponentType<any>)`
  cursor: pointer;
  padding: 5px;
  text-align: center;
  min-width: 300px;
  ${breakpoint('desktop')`
padding: 8px;
`}
  &:hover {
    opacity: 0.6;
  }
  &:focus {
    opacity: 0.6;
  }
  word-break: normal;
  padding: 5px 20px;
  width: 100%;
  cursor: auto;
  &:hover {
    opacity: 1;
  }
  &:focus {
    opacity: 1;
  }
`;
const StyledHeaderButton = styled(BaseButton as React.ComponentType<any>)`
  height: 38px;
  width: auto;
  padding: 0 12px;
  color: ${colorWhite};
  background-color: ${colorBlack};
  border: 2px solid ${colorWhite};
  border-radius: 28px;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
    color: ${colorWhite};
    background-color: ${colorBlack};
    border: 2px solid ${colorWhite};
    outline: none;
  }

  &:focus {
    color: ${colorBlack};
    background-color: ${colorWhite};
    border: 2px solid ${colorBlack};
    outline: 2px solid ${colorBlue};
  }
  &:active:disabled {
    outline: none;
  }
  &:active:enabled {
    opacity: 0.5;
    color: ${colorBlack};
    background-color: ${colorWhite};
    border-color: ${colorWhite};
    outline: 2px solid ${outlineBlue};
  }
  display: none;
  ${breakpoint('tablet')`
  display: block;
  `}
`;
const RightButtonHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.3em;
`;

const PhoneContainer = styled.a`
  display: flex;
  align-items: center;
  margin-left: 15px;
  font-weight: 400;
  font-size: 16px;
  top: 15px;
  color: white;
  opacity: 0.9;
  position: absolute;
  ${breakpoint('tablet')`
    position: initial;
    top: initial;
    left: initial;
  `}
  ${breakpoint('mobile')`
    position: relative;
    font-size: 14px;
    top: unset;
    left: unset;
  `}
`;

export const Layout = ({
  children,
  heading,
  mobile,
  mobile2x,
  tablet,
  tablet2x,
  desktop,
  desktop2x,
  showLoginBanner = false,
  showBackButton = true,
  burgerMenuActive = true,
}: LayoutProps): JSX.Element => {
  const history = useHistory();
  const msalContext = useMsal();

  const [isMenuOpen, setIsOpen] = useState<boolean>(false);

  const { setLoadingLock } = useLoading();
  const handleLogoutClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setLoadingLock(true);
    msalContext.instance.logout();
  };
  const handleLogoutPress = (e: React.KeyboardEvent) => {
    e.preventDefault();
    if (e.key == 'Enter') {
      setLoadingLock(true);
      msalContext.instance.logout();
    }
  };
  const handleLoginClick = (e: React.MouseEvent) => {
    e.preventDefault();
    msalContext.instance.loginRedirect(authenticationRequest);
  };

  const handleLoginPress = (e: React.KeyboardEvent) => {
    e.preventDefault();
    if (e.key == 'Enter') {
      msalContext.instance.loginRedirect(authenticationRequest);
    }
  };

  const [{ loading: isStatementLoading }, sendEmail] = useApiRequest(
    {
      method: 'POST',
      url: '/emails/statement-request',
    },
    // technically you need to be logged in to send this request, BUT,
    // this method is method instantiation is used in the menu, which is available to logged out users
    // so it needs to have the authRedirect set to false
    { manual: true, authRedirect: false },
  );

  const handleRequestStatement = async () => {
    try {
      await sendEmail();
      requestStatementSucceeded();
    } catch (error) {
      requestStatementFailed();
    } finally {
      if (isMenuOpen) setIsOpen(false);
    }
  };

  // Statement request feature END

  return (
    <LayoutContainer id="App">
      {burgerMenuActive && (
        <AuthenticatedTemplate>
          <BurgerMenu isOpen={isMenuOpen} setIsOpen={setIsOpen} />
        </AuthenticatedTemplate>
      )}
      <Header id="page-wrap">
        <Masthead>
          <HeaderContainer>
            <Link to="/">
              <Logo role="img" aria-label="Approved logo" />
            </Link>

            <AuthenticatedTemplate>
              <RightButtonHeader>
                {ApprovedPhoneNumber && (
                  <PhoneContainer href={`tel:${ApprovedPhoneNumber}`}>
                    <PhoneIcon style={{ height: '22px', width: '22px', filter: 'invert(1)', marginRight: 5 }} />
                    {ApprovedPhoneNumber}
                  </PhoneContainer>
                )}{' '}
                <StyledHeaderButton
                  trackingId={`Request-statement`}
                  onClick={handleRequestStatement}
                  disabled={isStatementLoading}
                  loading={isStatementLoading}
                >
                  Request a statement
                </StyledHeaderButton>
                <StyledHeaderButton
                  trackingId={`Add-new-project`}
                  onClick={() => {
                    history.push(`/projects`);
                  }}
                >
                  Start new project
                </StyledHeaderButton>
                <ButtonWrapper className="MyMenuButton" data-burger-menu-active={burgerMenuActive}>
                  <Button className="MyMenuButton-button">
                    <StyledPerson />
                  </Button>
                  <StyledMenu>
                    <MenuList>
                      <StyledMenuItemWrapped>
                        <Typography.Text>
                          Signed in as <br />
                        </Typography.Text>
                        <Typography.Text strong>
                          {getLatestAccount(msalContext)?.username ?? 'Unknown user'}
                        </Typography.Text>
                      </StyledMenuItemWrapped>
                      <Divider style={{ background: 'grey' }} />
                      <li tabIndex={0} onClick={handleLogoutClick} onKeyDown={handleLogoutPress}>
                        <StyledMenuItem className="MyMenuButton-menuItem">Log out</StyledMenuItem>
                      </li>
                    </MenuList>
                  </StyledMenu>
                </ButtonWrapper>
              </RightButtonHeader>
            </AuthenticatedTemplate>
          </HeaderContainer>
        </Masthead>
        {showLoginBanner && msalContext.inProgress === InteractionStatus.None && (
          <UnauthenticatedTemplate>
            <LoginBanner>
              <StyledExclamation />
              Already have an Account?
              <StyledLink tabIndex={0} onClick={handleLoginClick} onKeyDown={handleLoginPress}>
                Login here
              </StyledLink>
            </LoginBanner>
          </UnauthenticatedTemplate>
        )}

        <Hero
          mobile={mobile}
          mobile2x={mobile2x}
          tablet={tablet}
          tablet2x={tablet2x}
          desktop={desktop}
          desktop2x={desktop2x}
          showBackButton={showBackButton}
        >
          {heading}
        </Hero>
      </Header>
      <ContentWrapper>
        <ContentContainer>{children}</ContentContainer>
      </ContentWrapper>
    </LayoutContainer>
  );
};
