import React, { useEffect, useMemo, useState } from 'react';

import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe, StripeCardElementChangeEvent } from '@stripe/stripe-js';
import { Typography } from 'antd';
import { isEmpty } from 'lodash';
import styled from 'styled-components';
import { useLoading } from '../../../context/LoadingContext';
import { useApiRequest } from '../../../hooks/useApiRequest';
import { useFetchPaymentIntent } from '../../../hooks/useFetchPaymentIntent';
import { bodyFamily, borderColor, inputBorderRadius, inputFontSize, inputPadding } from '../../../theme.selectors';

import { ButtonWrapper, PaymentFormProps, StyledPrimaryButton } from './types';
import { FieldError } from '../../FormControls/FieldError';
import { Heading } from '../../Heading/Heading';

const FormContainer = styled.div`
  width: 100%;
`;

const StyledCardElement = styled(CardElement as React.ComponentType<any>)`
  font-family: ${bodyFamily};
  box-sizing: border-box;
  border-radius: ${inputBorderRadius};
  font-size: ${inputFontSize};
  padding: ${inputPadding};
  width: 100%;
  max-width: auto;
  border: 1px solid ${borderColor};
  height: 56px;
  background-color: ${({ theme }) => theme.colours.white};
`;

const cardOptions = {
  style: {
    base: {
      iconColor: 'black',
      color: 'black',
      fontWeight: 500,
      height: '56px',
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': { color: '#fce883' },
    },
    invalid: {
      iconColor: '#ffc7ee',
      color: '#ffc7ee',
    },
  },
};

const Component: React.FC<PaymentFormProps> = (props) => {
  const { onPayment, isDisabled, subject } = props;
  const { validFrom, applicationId: referenceId, id } = subject;
  const [processing, setProcessing] = useState<boolean>(false);
  const [disabled, setDisabled] = useState(isDisabled ?? false);
  const [error, setError] = useState<string | null>(null);

  // these can only be called inside of an Elements wrapper
  // see HOC wrapper below
  const stripe = useStripe();
  const elements = useElements();

  /**
   * Listen for changes in the CardElement
   *  and display any errors as the customer types their card details
   */
  const handleChange = async (event: StripeCardElementChangeEvent) => {
    setDisabled(event.empty);
    setError(event.error ? event.error.message : '');
  };

  const [{ data: confirmationData, error: confirmationError }, confirmPaymentIntent] = useApiRequest(
    {
      data: {
        quoteId: id,
        quoteReference: referenceId,
        validFrom,
      },
      method: 'post',
      url: '/payment/stripe/confirm',
    },
    {
      manual: true,
      authRedirect: true,
    },
  );

  const [{ status, data, errorMessage }, fetchPaymentIntent] = useFetchPaymentIntent(id);

  const { setLoading, setLoadingLock } = useLoading();

  useEffect(() => {
    fetchPaymentIntent();
  }, [subject]);

  useEffect(() => {
    if (setLoading && (status === 'idle' || status === 'fetching')) {
      setLoading(true);
    } else if (setLoading) {
      setLoading(false);
    }
  }, [setLoading, status]);

  useEffect(() => {
    if (!setLoading) return;
    if (error || errorMessage || confirmationError) {
      setLoading(false);
    }
  }, [error, errorMessage, confirmationError]);

  useEffect(() => {
    if (confirmationError) {
      const exception = confirmationError.response?.data as { message: string; type: string };
      setError(exception.message);
      if (['accept', 'invoice'].includes(exception.type)) {
        onPayment('paid');
      }
    } else if (confirmationData) {
      onPayment('completed');
    }
  }, [confirmationData, confirmationError]);

  const handleSubmit = async (ev: React.FormEvent) => {
    ev.preventDefault();

    if (setLoadingLock) {
      setLoadingLock(true);
    }

    setProcessing(true);
    const cardElement = elements?.getElement(CardElement);
    if (cardElement && data) {
      const payload = await stripe?.confirmCardPayment(data?.paymentIntent?.clientSecret, {
        payment_method: {
          card: cardElement,
        },
      });
      if (payload) {
        if (payload.paymentIntent) {
          if ('succeeded' === payload.paymentIntent.status) {
            try {
              await confirmPaymentIntent({
                params: {
                  intentId: payload.paymentIntent.id,
                },
              });
            } catch (error) {}
          }
        }
        if (payload.error) {
          setError(`Payment failed ${payload.error.message}`);
          setProcessing(false);
        } else {
          setError(null);
          setProcessing(false);
        }
      } else {
        setError(`Payment failed`);
        setProcessing(false);
      }
    }
    if (setLoadingLock) {
      setLoadingLock(false);
    }
  };

  return (
    <FormContainer>
      <form id="payment-form" onSubmit={handleSubmit}>
        <div>
          <label htmlFor="card-element">
            <Heading.H5>Pay with Card</Heading.H5>
            <Typography.Paragraph>Enter card details to pay & accept proposal.</Typography.Paragraph>
            <StyledCardElement id="card-element" options={cardOptions} onChange={handleChange} />
            {error && <FieldError>{error}</FieldError>}
            {errorMessage && <FieldError>{errorMessage}</FieldError>}
          </label>
        </div>

        <ButtonWrapper top={6}>
          <span id="payProposal">
            <StyledPrimaryButton
              trackingId={'payment-submission'}
              disabled={disabled || !isEmpty(error) || processing || !isEmpty(errorMessage)}
              buttonType="submit"
            >
              Pay &amp; Accept Proposal
            </StyledPrimaryButton>
          </span>
        </ButtonWrapper>
      </form>
    </FormContainer>
  );
};

// Have to wrap the Component in an elements tag so that stripe initialization works
const WithStripeElements = (Component: React.FC<PaymentFormProps>) => {
  const hoc = (props: PaymentFormProps) => {
    const stripeInit = useMemo(() => loadStripe(process.env.REACT_APP_STRIPE_KEY || ''), []);

    return (
      <Elements stripe={stripeInit}>
        <Component {...props} />
      </Elements>
    );
  };

  hoc.displayName = `${Component.displayName}WithStripeElements`;

  return hoc;
};

export const PaymentForm = WithStripeElements(Component);
