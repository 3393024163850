import React from 'react';
import styled from 'styled-components';
import { Checkbox as AntDCheckbox } from 'antd';
import { Control, useController } from 'react-hook-form';
import { Heading } from '../../Heading/Heading';
import { FieldError } from '../FieldError';

const StyledDiv = styled.div`
  flex: 20%;
`;
const StyledCheckbox = styled(AntDCheckbox as React.ComponentType<any>)`
  margin-left: 10px;
`;

const StyledFlexDiv = styled.div`
  display: flex;
  align-items: center;
`;

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  name: string;
  label: string;
  errors?: string;
  onChange: (checked: boolean) => void;
};

export const Checkbox = ({ name, label, control, errors, onChange }: Props): JSX.Element => {
  const {
    field: { onChange: controllerOnChange, ref, value },
  } = useController({ name, control });

  const handleChange = (e: { target: { checked: boolean } }) => {
    onChange && onChange(e.target.checked);
    controllerOnChange(e);
  };

  return (
    <StyledDiv>
      <StyledFlexDiv>
        <label htmlFor={name}>
          <Heading.H5>{label}</Heading.H5>
        </label>
      </StyledFlexDiv>
      <StyledCheckbox id={name} name={name} ref={ref} value={value} onChange={handleChange}></StyledCheckbox>
      {errors && <FieldError>{errors}</FieldError>}
    </StyledDiv>
  );
};
