import React from 'react';
import { Tag } from 'antd';
import styled from 'styled-components';
import { Task, TaskStatus as DTOTaskStatus } from '../../../../services/openapi.types.generated';
import { HelpIcon } from '../../../HelpIcon';
import { clientStatusDescription, approvedStatusDescription } from '../../../../utils/taskStatusDescriptions';
import { TaskProps } from '../props';

const StatusGrid = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 0px;
  margin: 0px;
  padding: 0px;
`;

const StatusText = styled.span`
  font-size: 14px;
`;

const StyledHelpIcon = styled((props: React.ComponentProps<typeof HelpIcon> & { statusColor: StatusColour }) => (
  <HelpIcon {...props} />
))`
  padding: 0px;
  margin: 0px;
  width: 14px;
  height: 14px;
  ${({ statusColor }) => (statusColor === 'darkblue' ? 'filter: invert(100%);' : '')}
  svg {
    margin: 0px !important;
    padding: 0px !important;
    bottom: 0px !important;
    left: 0px !important;
  }
`;

export type StatusProps = Pick<TaskProps, 'payment' | 'status' | 'assignedTo' | 'warning' | 'files' | 'id'>;

export type TaskStatusTextOption =
  | 'Completed'
  | 'Pending'
  | 'Ready for upload'
  | 'Scheduled for review'
  | 'In progress'
  | 'Awaiting information'
  | 'Not required'
  | 'Cancelled'
  | 'Status unknown';

const TaskStatusText: Record<string, TaskStatusTextOption> = {
  COMPLETE: 'Completed',
  PENDING: 'Pending',
  UPLOAD_READY: 'Ready for upload',
  REVIEW_SCHEDULED: 'Scheduled for review',
  IN_PROGRESS: 'In progress',
  RFI: 'Awaiting information',
  NOT_REQUIRED: 'Not required',
  CANCELLED: 'Cancelled',
  UNKNOWN: 'Status unknown',
};

const ClientTaskStatusText: Record<Task['status'], TaskStatusTextOption> = {
  ['NOT_REQUIRED']: 'Status unknown',
  ['PENDING']: 'Ready for upload',
  ['ACTIVE']: 'Ready for upload',
  ['RFI']: 'Awaiting information',
  ['REVIEW']: 'Scheduled for review',
  ['COMPLETE']: 'Completed',
  ['CANCELLED']: 'Cancelled',
};

const ApprovedTaskStatusText: Record<Task['status'], TaskStatusTextOption> = {
  ['NOT_REQUIRED']: 'Status unknown',
  ['PENDING']: 'In progress',
  ['ACTIVE']: 'In progress',
  ['RFI']: 'Awaiting information',
  ['REVIEW']: 'Scheduled for review',
  ['COMPLETE']: 'Completed',
  ['CANCELLED']: 'Cancelled',
};

const statusText = ({ status, payment, warning, files, assignedTo }: StatusProps): TaskStatusTextOption => {
  const requiresPayment = payment?.status !== 'paid';

  if (requiresPayment) {
    return 'Pending';
  }

  // special case so that we show the review status for a client
  // after uploading only a single file for an RFI
  // because status changes to review after client uploads a file
  // but they might still be doing further uploads
  // TODO: Should this have assigned to user from the backend maybe in this case???
  if (warning && files?.length && status === DTOTaskStatus.REVIEW) {
    return ClientTaskStatusText[status];
  }

  if (assignedTo === 'user') {
    return ClientTaskStatusText[status];
  }

  if (assignedTo === 'approved') {
    return ApprovedTaskStatusText[status];
  }

  return 'Status unknown';
};

type StatusColour = 'green' | 'purple' | 'blue' | 'darkblue' | 'orange' | 'grey' | 'default';

const statusColor = (text: keyof typeof TaskStatusText): StatusColour => {
  const statusColorObj: Record<keyof typeof TaskStatusText, StatusColour> = {
    [TaskStatusText.COMPLETE]: 'green',
    [TaskStatusText.PENDING]: 'purple',
    [TaskStatusText.UPLOAD_READY]: 'blue',
    [TaskStatusText.IN_PROGRESS]: 'blue',
    [TaskStatusText.REVIEW_SCHEDULED]: 'darkblue',
    [TaskStatusText.RFI]: 'orange',
    [TaskStatusText.NOT_REQUIRED]: 'grey',
    [TaskStatusText.CANCELLED]: 'grey',
    [TaskStatusText.UNKNOWN]: 'grey',
  };

  return statusColorObj[text] ?? 'default';
};

export const TaskStatus: React.FC<StatusProps> = (props: StatusProps): JSX.Element => {
  const { id, assignedTo } = props;
  const title = statusText(props);
  const color = statusColor(title);

  const statusDescription = assignedTo === 'user' ? clientStatusDescription[title] : approvedStatusDescription[title];

  return (
    <Tag color={color} style={{ marginRight: '0px' }}>
      <StatusGrid>
        <StatusText>{title}</StatusText>
        {statusDescription?.length ? (
          <>
            &nbsp;&nbsp;
            <StyledHelpIcon id={id} statusColor={color}>
              {statusDescription}
            </StyledHelpIcon>
          </>
        ) : null}
      </StatusGrid>
    </Tag>
  );
};

type DateProps = Pick<TaskProps, 'status' | 'payment' | 'assignedTo' | 'due'>;

const getDatePrefix = ({ status, assignedTo, payment }: DateProps): string => {
  const paymentStatus = payment?.status;
  if ('complete' === status.toLowerCase()) {
    return 'Completed';
  } else if ('approved' !== assignedTo?.toLowerCase() && 'paid' !== paymentStatus) {
    return 'Due';
  } else if ('approved' === assignedTo?.toLowerCase() && 'paid' === paymentStatus) {
    return 'Est';
  } else {
    return 'Due';
  }
};

const DueDiv = styled.div`
  ${StatusText}
  height: 100%;
  white-space: nowrap;
`;

export const Eta: React.FC<DateProps> = (props) => {
  return (
    <DueDiv>
      {getDatePrefix(props)}: {props.due}
    </DueDiv>
  );
};
