import React from 'react';
import styled from 'styled-components';

type BaseFabButtonProps = {
  children: React.ReactNode;
  trackingId: string;
  className?: string;
  disabled?: boolean;
  buttonType?: 'button' | 'submit' | 'reset';
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};
const FloatingFab = styled.button`
  width: 62px;
  height: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: fixed;
  bottom: 1vh;
  right: 5vw;
  cursor: pointer;
  z-index: 2;
`;
export const BaseFabButton = ({
  buttonType = 'button',
  children,
  onClick,
  className,
  trackingId,
  disabled,
}: BaseFabButtonProps): JSX.Element => {
  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    onClick && onClick(event);
  };
  return (
    <FloatingFab type={buttonType} className={className} disabled={disabled} onClick={handleClick}>
      {children}
    </FloatingFab>
  );
};
