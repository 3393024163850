import { Dispatch, Reducer, useCallback, useMemo, useReducer } from 'react';
import { StatusFilters } from '../../components/DashboardGrid/Filters/types';
import { stripEmptyEntries } from '../../utils/stripEmptyEntries';

type DashboardFormState = {
  sort: string;
  address: string;
  applicationId: string;
  status: StatusFilters;
  clientReference: string;
};
type FormKey = keyof DashboardFormState;
type FormValue<K extends FormKey> = DashboardFormState[K];

const defaultState: DashboardFormState = {
  sort: '-status',
  address: '',
  applicationId: '',
  status: 'all',
  clientReference: '',
};

type UpdateAction = { type: 'update'; payload: { name: FormKey; value: FormValue<FormKey> } };

type DashboardFormAction = UpdateAction;

const dashboardFormReducerMap: Record<
  DashboardFormAction['type'],
  (s: DashboardFormState, a: DashboardFormAction) => DashboardFormState
> = {
  update: (state, { payload }) => stripEmptyEntries({ ...state, [payload.name]: payload.value }),
};

const dashboardFormReducer: Reducer<DashboardFormState, DashboardFormAction> = (state, action) => {
  return dashboardFormReducerMap[action.type](state, action);
};

type Update = <K extends FormKey, V extends FormValue<K>>(name: K) => (value: V) => void;

export type UseDashboardForm = {
  (initialState?: DashboardFormState): readonly [DashboardFormState, Update, Dispatch<DashboardFormAction>];
};

export const useDashboardForm: UseDashboardForm = (initialState = defaultState) => {
  const [state, dispatch] = useReducer(dashboardFormReducer, initialState);

  const update = useCallback<Update>(
    (name) => (value) => dispatch({ type: 'update', payload: { name, value } }),
    [dispatch],
  );

  const returnValue = useMemo(() => [state, update, dispatch] as const, [state, update, dispatch]);

  return returnValue;
};
