import { InteractionStatus } from '@azure/msal-browser';
import { IMsalContext, useMsal } from '@azure/msal-react';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { makeUseAxios, Options, UseAxiosResult } from 'axios-hooks';
import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { authenticationRequest } from '../components/AuthenticatedRoute';
import { getBearerToken } from '../services/authentication';

type ApiRequestOptions = Options & {
  authRedirect?: boolean;
};

const baseUrl = process.env.REACT_APP_API_HOST;

const axiosApiInstance = axios.create({
  baseURL: `${baseUrl}/api`,
});

const useAxios = makeUseAxios({ axios: axiosApiInstance });

export type IntegrationApiError = { error?: string; message?: string };

/**
 * Hook to make requests to API.
 * * Includes API base url.
 * * Includes authorization token in header if it exists, and fetches it if not present
 */
export const useApiRequest = <TResult, TData = any, TError = IntegrationApiError>(
  config: string | AxiosRequestConfig,
  options: ApiRequestOptions = {},
): UseAxiosResult<TResult, TData, TError> => {
  const { authRedirect } = options;

  const msalContext = useMsal();
  // need a ref to msalContext so that we can access
  // the latest instance in the interceptor
  const msalRef = useRef<IMsalContext>();
  useEffect(() => {
    msalRef.current = msalContext;
  }, [msalContext]);

  async function waitForMsalInteraction() {
    if (msalRef.current?.inProgress === InteractionStatus.None) return;
    await new Promise<void>((resolve) => {
      setTimeout(() => resolve(), 100);
    });
    await waitForMsalInteraction();
  }

  useEffect(() => {
    const interceptorId = axiosApiInstance.interceptors.request.use(async (config) => {
      try {
        // ensure that we are not in the middle of an interaction
        // before trying to get the token
        await waitForMsalInteraction();
        const bearer = await getBearerToken(msalContext);
        if (bearer) config.headers.authorization = `Bearer ${bearer}`;
      } catch (e) {
        if (authRedirect) {
          await msalContext.instance.loginRedirect(authenticationRequest);
        }
      }

      return config;
    });
    return () => {
      axiosApiInstance.interceptors.request.eject(interceptorId);
    };
  }, [msalContext]);

  return useAxios<TResult, TData, TError>(config, options);
};

/**
 * Redirect to the errors page when any of the provide error values are defined
 * @param errors
 */
export const useErrorRedirect = (errors: Array<AxiosError | unknown>): void => {
  const history = useHistory();
  useEffect(() => {
    // if no errors, then do nothing
    if (!errors.some((error) => !!error)) {
      return;
    }

    const axiosError = errors.find((error) => (error as AxiosError)?.isAxiosError) as AxiosError<IntegrationApiError>;
    let message = undefined;
    let errorCode = undefined;
    if (axiosError && axiosError.response) {
      const { response, code } = axiosError;
      const { status } = response;
      message = response?.data?.error || response?.data?.message;
      errorCode = (status && `${status}`) || code;
    } else {
      const error = errors.find((error) => !!error) as Error;
      message = error.message;
    }
    history.push({
      pathname: '/error',
      search: `?message=${message ?? 'A network error occurred'}${errorCode ? `&error=${errorCode}` : ''}`,
    });
  }, errors);
};
