import React from 'react';

import { AuthenticatedTemplate } from '@azure/msal-react';
import { ReactComponent as LeftIcon } from '../../svg/chevron-left.svg';
import { colorBlack, colorWhite } from '../../theme.selectors';
import { ContentContainer } from '../ContentContainer';
import { Heading } from '../Heading';
import { RouterLink } from '../Link/RouterLink';

import styled from 'styled-components';
import { breakpoint } from 'styled-components-breakpoint';
import { px, py } from 'styled-components-spacing';

type HeroProps = {
  children: React.ReactNode;
  mobile: string;
  mobile2x: string;
  tablet: string;
  tablet2x: string;
  desktop: string;
  desktop2x: string;
  showBackButton?: boolean;
};

const HeroWrapper = styled.div`
  position: relative;
  ${px({ mobile: 4, tablet: 5 })}

  ${breakpoint('mobile')`
    height: 220px;
  `}

  ${breakpoint('tablet')`
    height: 300px;
  `}

  ${breakpoint('desktop')`
    height: 385px;
  `}
`;

const buildQuery = (standard: string, retina: string, width?: number) => {
  const mediaWidth = width ? `and (min-width:${width}px)` : '';

  return `
    @media
    only screen ${mediaWidth} {
      background-image: url('${standard}');
    }

    @media
    only screen and (-webkit-min-device-pixel-ratio: 2) ${mediaWidth},
    only screen and (min--moz-device-pixel-ratio: 2) ${mediaWidth},
    only screen and (-o-min-device-pixel-ratio: 2/1) ${mediaWidth},
    only screen and (min-device-pixel-ratio: 2) ${mediaWidth},
    only screen and (min-resolution: 192dpi) ${mediaWidth},
    only screen and (min-resolution: 2dppx) ${mediaWidth} {
      background-image: url('${retina}');
    }
  `;
};

type ImageProps = {
  mobile: string;
  mobile2x: string;
  tablet: string;
  tablet2x: string;
  desktop: string;
  desktop2x: string;
};

const HeroBackgroundImage = styled.div<ImageProps>`
  ${({ mobile, mobile2x }) => buildQuery(mobile, mobile2x)}
  ${({ tablet, tablet2x, theme }) => buildQuery(tablet, tablet2x, theme.breakpoints.tablet)}
  ${({ desktop, desktop2x, theme }) => buildQuery(desktop, desktop2x, theme.breakpoints.desktop)}
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: ${colorBlack};
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;

  ${breakpoint('desktop')`
    bottom: -150px;
  `}

  :after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: ${colorBlack};
    opacity: 0.5;
  }
`;

const StyledContentContainer = styled.div`
  max-width: 1240px;
  margin: 0 auto;
  box-sizing: border-box;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  ${py({ mobile: 4, tablet: 5 })};
`;

const HeroLink = styled(RouterLink as React.ComponentType<any> as React.ComponentType<any>)`
  color: ${colorWhite};
  display: flex;

  > *:not(:last-child) {
    margin-right: 0.5em;
  }
`;

const HeroHeading = styled(Heading.H1 as React.ComponentType<any> as React.ComponentType<any>)`
  color: ${colorWhite};
  margin-top: auto;
`;

const StyledBackButton = styled(LeftIcon as React.ComponentType<any>)`
  width: 24px;
  height: 24px;
`;

export const Hero = ({
  children,
  mobile,
  mobile2x,
  tablet,
  tablet2x,
  desktop,
  desktop2x,
  showBackButton = true,
}: HeroProps): JSX.Element => {
  return (
    <HeroWrapper>
      <HeroBackgroundImage
        id="hero"
        mobile={mobile}
        mobile2x={mobile2x}
        tablet={tablet}
        tablet2x={tablet2x}
        desktop={desktop}
        desktop2x={desktop2x}
      />
      <StyledContentContainer>
        <AuthenticatedTemplate>
          {showBackButton && (
            <HeroLink to="/" trackingId="track-this-link">
              <StyledBackButton /> <span>Your Projects</span>
            </HeroLink>
          )}
        </AuthenticatedTemplate>
        <HeroHeading>{children}</HeroHeading>
      </StyledContentContainer>
    </HeroWrapper>
  );
};
