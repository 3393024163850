import React from 'react';
import Modal, { Props as ReactModalProps, Styles as ReactModalStyles } from 'react-modal';
import styled from 'styled-components';
import breakpoint, { map } from 'styled-components-breakpoint';
import { theme } from '../../../theme';
import { baseStyles, fontSize, Heading, lineHeight } from '../../Heading';

export type BaseModalProps = {
  title?: string;
  children: React.ReactNode;
  primaryAction?: React.ReactNode;
  secondaryAction?: React.ReactNode;
  reactModalProps: ReactModalProps;
  className?: string;
};

const ModalContainer = styled.div`
  ${breakpoint('mobile')`
    padding-top: 58px;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 32px;
  `}
  ${breakpoint('tablet')`
    padding-top: 48px;
    padding-left: 64px;
    padding-right: 64px;
    padding-bottom: 32px;
  `}
  ${breakpoint('desktop')`
    padding-top: 48px;
    padding-left: 64px;
    padding-right: 64px;
    padding-bottom: 32px;
  `}
`;

const Title = styled.h3`
  ${baseStyles};
  margin: 0;
  font-weight: 700;
  ${map(theme.lineHeights.h3, lineHeight)};
  ${map(theme.fontSizes.h3, fontSize)};
  margin-bottom: 16px;
  line-height: 36px;
`;

const ChildrenContainer = styled.div``;

const ActionsContainer = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  > *:not(:last-child) {
    margin-bottom: 8px;
  }
  ${breakpoint('tablet')`
  flex-direction: row;
  > *:not(:last-child) {
    margin-bottom: 0px;
    margin-right: 8px;
  }
  `}
`;
/**
 * Basic styling for modals.
 */
const defaultStyle: ReactModalStyles = {
  content: {
    padding: '0',
    position: 'absolute',
    inset: '50% 50% auto 50%',
    width: '100%',
    maxWidth: '770px',
    transform: 'translate(-50%, -50%)',
    top: '50%',
    bottom: 'auto',
    left: '50%',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    zIndex: 5,
  },
};

export const BaseModal = ({
  primaryAction,
  secondaryAction,
  children,
  title,
  reactModalProps,
  className,
}: BaseModalProps): JSX.Element => {
  return (
    <Modal style={defaultStyle} className={className} {...reactModalProps}>
      <ModalContainer>
        {title && <Title>{title}</Title>}
        <ChildrenContainer>{children}</ChildrenContainer>
        <ActionsContainer>
          {primaryAction}
          {secondaryAction}
        </ActionsContainer>
      </ModalContainer>
    </Modal>
  );
};
