import React from 'react';
import styled from 'styled-components';
import { breakpoint } from 'styled-components-breakpoint';
import { Margin } from 'styled-components-spacing';
import { useSubject } from '../../../hooks/useSubject';
import { Project } from '../../../services/openapi.types.generated';
import { grayText } from '../../../theme.selectors';
import { Copy } from '../../Copy';
import { Heading } from '../../Heading/Heading';

import { DownloadDocumentsButton } from '../DownloadDocumentsButton';
import { Reason } from '../Reason';

type MilestoneHeaderProps = {
  referenceId?: string;
  status?: string;
  pathway?: string;
  description?: string;
  reason?: string;
  subject: Project;
};

const ProjectMilestoneWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${breakpoint('tablet')`
    flex-direction: row;
  `}

  ${breakpoint('mobile')`
    flex-direction: column;
  `}
`;

const ProjectInfoWrapper = styled.div`
  flex: 2 0;
`;

const DownloadButtonWrapper = styled.div`
  flex: 0 1;

  display: none;
  ${breakpoint('tablet')`
    order: inherit;
    margin-top: 0;
  `}

  ${breakpoint('mobile', 'tablet')`
    order: 3;
    margin-top: 2rem;
    display: block;
  `}
`;

const ProjectMilestone = styled(Heading.H2 as React.ComponentType<any>)`
  color: ${grayText};
`;

const SmallerCopy = styled(Copy as React.ComponentType<any>)`
  font-size: 16px;
  line-height: 24px;
  margin: 0;
`;

const StyledStatus = styled(Heading.H2 as React.ComponentType<any>)`
  ${breakpoint('mobile')`
  font-size: 26px;
  `}
  ${breakpoint('tablet')`
  font-size: 35px;
  `}
`;

const QuoteCopy = styled(Copy as React.ComponentType<any>)`
  margin-top: 0;
`;

export const MilestoneHeader = ({
  status,
  reason,
  description,
  pathway,
  referenceId,
  subject,
}: MilestoneHeaderProps): JSX.Element => {
  const subjectType = useSubject();
  return (
    <ProjectMilestoneWrapper>
      <ProjectInfoWrapper>
        <Margin bottom={{ mobile: 5, tablet: 6 }} top={6}>
          {referenceId && <QuoteCopy>Fee Proposal: {referenceId}</QuoteCopy>}
          {status && <StyledStatus>{status}</StyledStatus>}
          {pathway && <ProjectMilestone>{pathway}</ProjectMilestone>}
          {description && <SmallerCopy>{description} </SmallerCopy>}
        </Margin>
        <Margin bottom={6}>{reason && <Reason description={reason} />}</Margin>
      </ProjectInfoWrapper>
      {subjectType === 'projects' && subject.status !== 'completed' && (
        <DownloadButtonWrapper>
          <DownloadDocumentsButton subject={subject} />
        </DownloadButtonWrapper>
      )}
    </ProjectMilestoneWrapper>
  );
};
