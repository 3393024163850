import { type } from 'os';
import React, { RefObject, useCallback, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { reCaptchaSiteKey } from '../components/Form/constants';

const getRecaptchaToken = async (reCaptchaRef: RefObject<ReCAPTCHA>) => {
  console.log('Calling getRecaptchaToken');
  if (!reCaptchaRef.current) {
    console.log('No recaptcha found');
    return null;
  }

  const token = await reCaptchaRef.current.executeAsync();
  console.log(token);
  return token;
};

export type UseRecaptcha = [RefObject<ReCAPTCHA>, () => Promise<string | null>];

/**
 * Hook which provides `ref` object for passing into a Recaptcha component, along with the execute function to call when a token is required from the Recaptcha component.
 */
export const useReCaptcha = (): UseRecaptcha => {
  const ref = useRef<ReCAPTCHA | null>(null);
  const execute = useCallback(async () => {
    try {
      const token = await getRecaptchaToken(ref);
      console.log('Finished getting token', token);
      return token;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }, []);
  return [ref, execute];
};

type ContextType = { ref: React.RefObject<ReCAPTCHA>; getToken: () => Promise<string | null> };

export function RecaptchaProvider({ children }: { children: React.ReactNode }): JSX.Element {
  const [ref, getToken] = useReCaptcha();

  return (
    <>
      <ReCAPTCHA ref={ref} size="invisible" badge="bottomleft" sitekey={reCaptchaSiteKey} />
      <RecaptchaContext.Provider value={{ ref, getToken }}>{children}</RecaptchaContext.Provider>
    </>
  );
}

export const RecaptchaContext = React.createContext<ContextType>({
  ref: React.createRef(),
  getToken: async () => null,
});
