import styled from 'styled-components';
import { BaseButton, BaseButtonProps } from '../BaseButton';
import { colorBlack, colorWhite, outlineBlue } from '../../../theme.selectors';

export type TertiaryButtonProps = BaseButtonProps;

export const TertiaryButton = styled(BaseButton as React.ComponentType<any>)`
  box-sizing: border-box;
  height: 48px;
  width: 186px;
  border: 2px solid ${colorBlack};
  color: ${colorBlack};
  border-radius: 28px;
  background-color: ${colorWhite};
  cursor: pointer;
  &:focus {
    outline: 2px solid ${outlineBlue};
    outline-offset: 1px;
  }
  &:active {
    outline: none;
  }
  &:hover:enabled {
    border: none;
    color: ${colorWhite};
    background-color: ${colorBlack};
  }

  &:active:enabled {
    border: none;
    color: ${colorWhite};
    background-color: ${colorBlack};
    opacity: 0.6;
  }

  &:disabled {
    opacity: 0.4;
    cursor: auto;
  }
`;
