import React from 'react';

import { Image } from 'antd';
import styled from 'styled-components';
import { ReactComponent as PhoneIcon } from '../../../svg/phone.svg';
import { bodyFamily, colorBlack, colorWhite, extraLightGray, grayText, outlineBlue } from '../../../theme.selectors';
import { BaseButton } from '../../Button/BaseButton';
import { ApprovedPhoneNumber } from '../../Form/constants';
import { fallbackPhoto } from './fallback-photo';
import { Heading } from '../../Heading/Heading';

type ExpertProps = {
  userId: string;
  name: string;
  phoneNumber?: string;
  email?: string;
  className?: string;
};

const StyledPanel = styled.div`
  background: ${colorWhite};
  border: 1px solid ${extraLightGray};
  padding: 21px;
  display: flex;
  border-radius: 4px;
  flex-direction: column;
  border-radius: 4px;
  justify-content: space-evenly;
  padding: 48px 32px 32px;
`;
const FaintHeading = styled.div`
  color: ${grayText};
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
`;
const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;
const FlexLink = styled.a`
  display: flex;
  align-items: center;
  text-decoration: underline;
  line-height: 1.2;
  margin: 0;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 32px;
`;

const PhoneDiv = styled.div`
  margin-left: 8px;
`;
const ExpertNameContainer = styled.div`
  text-align: center;
  margin-bottom: 21px;
`;
const StyledPhoneIcon = styled(PhoneIcon as React.ComponentType<any>)`
  height: 35px;
  width: 35px;
`;
const StyledButton = styled(BaseButton as React.ComponentType<any>)`
  box-sizing: border-box;
  height: 48px;
  width: 186px;
  border: 2px solid ${colorBlack};
  color: ${colorBlack};
  border-radius: 28px;
  background-color: ${colorWhite};
  cursor: pointer;
  &:focus {
    outline: 2px solid ${outlineBlue};
    outline-offset: 1px;
  }
  &:active {
    outline: none;
  }
  &:hover:enabled {
    border: none;
    color: ${colorWhite};
    background-color: ${colorBlack};
  }

  &:active:enabled {
    border: none;
    color: ${colorWhite};
    background-color: ${colorBlack};
    opacity: 0.6;
  }

  &:disabled {
    opacity: 0.4;
    cursor: auto;
  }
  font-family: ${bodyFamily};
  font-size: 18px;
  letter-spacing: 0;
  line-height: 20px;
`;

const apiUrl = process.env.REACT_APP_API_HOST + '/api';
/**
 * Given that an authenticated user is logged in
When the user is viewing their project
Then the name of the Approved expert assigned to the project is shown
 * @param {string} userId - The ID of the expert
 * @param {string} name - The name of the expert
 * @param {string} phoneNumber -  The experts phone number, provided in a telto link
 * @param {string} email -  The experts email address provided in an email link
 * @param {string} imageUrl -  The experts photo
 * @param {string} className -  A class name that can be passed down to control the styling
 */
export const ExpertPanel = ({ userId, name, phoneNumber, email, className }: ExpertProps): JSX.Element => {
  const phoneSupport = phoneNumber ?? ApprovedPhoneNumber;

  return (
    <StyledPanel className={className}>
      <FlexDiv>
        <Image
          style={{
            width: '160px',
            height: '160px',
            borderRadius: '50%',
            marginBottom: '30px',
          }}
          preview={false}
          fallback={fallbackPhoto}
          src={`${apiUrl}/users/${userId}/photo/240x240`}
        />
        <ExpertNameContainer>
          {' '}
          <FaintHeading>Your Approved Expert:</FaintHeading>
          <Heading.H4>{name}</Heading.H4>
        </ExpertNameContainer>
        {phoneSupport && (
          <FlexLink href={`tel:${phoneSupport}`}>
            <StyledPhoneIcon /> <PhoneDiv>{phoneSupport}</PhoneDiv>
          </FlexLink>
        )}
        {email && (
          <a href={`mailto:${email}`}>
            <StyledButton trackingId="expert-panel">Ask a question</StyledButton>
          </a>
        )}
      </FlexDiv>
    </StyledPanel>
  );
};
