import styled from 'styled-components';
import { BaseButton, BaseButtonProps } from '../BaseButton';
import { colorBlack, colorWhite, outlineBlue, bodyFamily } from '../../../theme.selectors';

export type PrimaryButtonProps = BaseButtonProps;

export const PrimaryButton = styled(BaseButton as React.ComponentType<any>)`
  height: 48px;
  width: 336px;
  border-radius: 27px;
  background-color: ${colorBlack};
  font-family: ${bodyFamily};
  font-size: 18px;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  color: ${colorWhite};
  border: none;
  cursor: pointer;
  &:hover {
    background-color: ${colorBlack};
    color: ${colorWhite};
    opacity: 0.7;
  }
  &:focus {
    outline: 2px solid ${outlineBlue};
    opacity: 0.5;
    background-color: ${colorBlack};
    color: ${colorWhite};
  }
  &:active {
    outline: none;
  }

  &:active:enabled {
    border: none;
    color: ${colorBlack};
    background-color: #d9d9d7;
  }

  &:disabled {
    opacity: 0.5;
    cursor: auto;
  }
`;
