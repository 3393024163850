import React, { useEffect, useState } from 'react';
import { Progress } from 'antd';
import axios from 'axios';
import styled from 'styled-components';
import { pl, px } from 'styled-components-spacing';
import { useDebouncedCallback } from 'use-debounce';
import { useApiRequest } from '../../../../hooks/useApiRequest';
import { DocumentRequest, DocumentResponse } from '../../../../services/openapi.types.generated';
import { ReactComponent as CrossIcon } from '../../../../svg/cross.svg';
import { ReactComponent as FolderIcon } from '../../../../svg/folder-circle.svg';
import { formatSize } from '../../../helpers';
import { FieldError } from '../../FieldError';
import { gray } from '../../../../theme.selectors';

type FileProps = {
  file: File;
  onCancel: () => void;
  progress: number;
  errorMessage: string | null;
  status: 'error' | 'complete' | 'uploading' | null;
};

type ContainerProps = {
  isLoading: boolean;
  isError: boolean;
};

const BoxContainer = styled.div<ContainerProps>`
  border: 1px solid ${gray};
  border-radius: 4px;
  background-color: ${({ isError, theme }): string => (isError ? theme.colours.red : theme.colours.extraLightGray)};
  display: flex;
  align-items: center;
  padding: 16px;
  opacity: ${({ isLoading }): number => (isLoading ? 0.5 : 1)};

  svg {
    flex: 0 0 auto;
  }
`;

const StyledDiv = styled.div`
  font-size: 18px;
  text-decoration: underline;
  ${px(4)};
  flex: 1 1 0%;
  display: flex;
  min-width: 0;
`;

const FileName = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const FileSize = styled.div`
  word-break: keep-all;
  ${pl(2)};
`;

const Container = styled.div``;

const StyledCross = styled(CrossIcon as React.ComponentType<any>)`
  cursor: pointer;
`;

export const FileBox = ({ file, onCancel, status, progress, errorMessage }: FileProps): JSX.Element => {
  const { size, name } = file;

  return (
    <Container>
      <BoxContainer isLoading={status === 'uploading'} isError={status === 'error'}>
        <FolderIcon />
        <StyledDiv>
          <FileName>{name}</FileName>
          <FileSize>({formatSize(size)})</FileSize>
        </StyledDiv>
        <StyledCross onClick={() => onCancel()} />
      </BoxContainer>
      {progress < 100 && status !== 'error' && <Progress percent={progress} />}
      {status === 'error' && <Progress status="exception" percent={1} />}
      {errorMessage && <FieldError>{errorMessage}</FieldError>}
    </Container>
  );
};
