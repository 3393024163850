import React from 'react';
import styled, { css } from 'styled-components';
import { gray } from '../../../theme.selectors';
import { buildBaseLink, BaseLinkProps } from '../BaseLink';

export type LinkProps = BaseLinkProps &
  React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>;

export const linkStyles = css`
  color: inherit;

  &:hover {
    opacity: 0.6;
    color: ${gray};
  }

  &:active {
    color: ${gray};
  }

  &:disabled {
    opacity: 0.4;
  }
`;

const A = (props: React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>) => (
  <a {...props} />
);

const aLink = buildBaseLink<LinkProps>(A);

const StyledBaseLink = styled(aLink as React.ComponentType<any>)`
  ${linkStyles}
`;

export const Link = StyledBaseLink;
