import React, { useEffect, useMemo } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { breakpoint } from 'styled-components-breakpoint';
import { Margin } from 'styled-components-spacing';
import { usePageTitle } from '../../../context/PageTitleContext';
import { RecaptchaProvider } from '../../../hooks/useRecaptcha';
import { Task, Project, Milestone } from '../../../services/openapi.types.generated';
import { addressInfo } from '../../../utils/addressInfo';
import { PrimaryButton } from '../../Button/PrimaryButton';
import { Heading } from '../../Heading/Heading';
import { MessageTask } from '../../Task/MessageTask';
import { DownloadDocumentsButton } from '../DownloadDocumentsButton';

import {
  isTaskAssignedToApproved,
  isTaskAssignedToUser,
  isTaskPaymentRequired,
  isTaskStatusComplete,
} from '../task-logic';

import { TaskListWithIndex as GroupedTaskList } from './GroupedTaskList';

type ActionProps = {
  actionTasks: Array<Task>;
  id: string;
  subject: Project;
  setProject?: (project: Project) => void;
  currentMilestone?: Milestone;
};

const Container = styled.div``;

const ListSection = styled(Margin as React.ComponentType<any>)``;

const StyledPrimaryButton = styled(PrimaryButton as React.ComponentType<any>)`
  max-width: none;
  width: 100%;
  ${breakpoint('tablet')`
    max-width: 243px;
  `}
  ${breakpoint('desktop')`
    max-width: 253px;
  `}
`;

const CompletedHeader = styled.div`
  display: flex;
  ${breakpoint('desktop')`
  flex-direction: row;
  justify-content: space-between;
  `}
  ${breakpoint('tablet')`
  flex-direction: row;
  justify-content: space-between;
  `}
  ${breakpoint('mobile')`
  flex-direction: column;
  gap: 12px;
  `};
`;

const StyledCompletedButton = styled.span`
  ${breakpoint('mobile', 'tablet')`
    display: none;
  `}
`;

const HeaderContainer = styled(Margin as React.ComponentType<any>)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${breakpoint('tablet')`
  flex-direction: row;
  `}
`;

const StyledRecaptchaDisclaimer = styled.div`
  margin-top: 60px;
  color: ${({ theme }) => theme.colours.grayText};
  font-size: 1em;
`;

export const ActionTaskSection = ({ actionTasks, subject, setProject, id }: ActionProps): JSX.Element => {
  const { setTitle } = usePageTitle();

  useEffect(() => {
    setTitle(addressInfo(subject));
  }, [subject]);

  const milestones = subject.milestones;

  const completedTasks = useMemo(() => actionTasks.filter(isTaskStatusComplete), [actionTasks]);

  const pendingPayment = useMemo(() => actionTasks.filter(isTaskPaymentRequired), [actionTasks]);

  const assignedToApprove = useMemo(
    () =>
      actionTasks.filter((actionTask) => {
        return (
          !isTaskStatusComplete(actionTask) &&
          isTaskAssignedToApproved(actionTask) &&
          !isTaskPaymentRequired(actionTask)
        );
      }),
    [actionTasks],
  );
  const hasDelayed = useMemo(
    () => actionTasks.some((actionTask) => actionTask.isDelayed && isTaskAssignedToUser(actionTask)),
    [actionTasks],
  );
  const assignedToUser = useMemo(
    () =>
      actionTasks.filter((actionTask) => {
        return (
          !isTaskStatusComplete(actionTask) && isTaskAssignedToUser(actionTask) && !isTaskPaymentRequired(actionTask)
        );
      }),
    [actionTasks],
  );

  const history = useHistory();

  return (
    <RecaptchaProvider>
      <Container>
        {hasDelayed && (
          <Margin bottom={{ mobile: 6, tablet: 7, desktop: 9 }} top={{ mobile: 0, desktop: 2 }}>
            <MessageTask
              title="You have a task that requires you to upload documents"
              description="Please upload your documentation for the tasks that you chose to arrange yourself."
            />
          </Margin>
        )}
        {pendingPayment.length > 0 && (
          <ListSection bottom={5}>
            <HeaderContainer bottom={{ mobile: 5, desktop: 4 }}>
              <div>
                <Heading.H2>Pending Proposal</Heading.H2>
              </div>
              <StyledPrimaryButton
                trackingId={'continue-button'}
                onClick={() => {
                  const quoteId = subject?.activeQuote ?? id;
                  history.push(`/quotes/${quoteId}/accept`);
                }}
              >
                Continue to proposal
              </StyledPrimaryButton>
            </HeaderContainer>
            <GroupedTaskList
              milestones={milestones}
              tasks={pendingPayment}
              projectId={id}
              setProject={setProject}
              projectStatus={subject.status}
            />
          </ListSection>
        )}
        {assignedToUser.length > 0 && (
          <ListSection bottom={5}>
            <HeaderContainer bottom={{ mobile: 5, desktop: 4 }}>
              <Heading.H2>Tasks Assigned to you</Heading.H2>
            </HeaderContainer>
            <GroupedTaskList
              milestones={milestones}
              tasks={assignedToUser}
              projectId={id}
              setProject={setProject}
              projectStatus={subject.status}
            />
          </ListSection>
        )}
        {assignedToApprove.length > 0 && (
          <ListSection bottom={5}>
            <HeaderContainer bottom={{ mobile: 5, desktop: 4 }}>
              <Heading.H2>Tasks Assigned to Approved</Heading.H2>
            </HeaderContainer>
            <GroupedTaskList
              milestones={milestones}
              tasks={assignedToApprove}
              projectId={id}
              setProject={setProject}
              projectStatus={subject.status}
            />
          </ListSection>
        )}
        {completedTasks.length > 0 && (
          <ListSection bottom={5}>
            <Margin bottom={{ mobile: 5, desktop: 4 }}>
              <CompletedHeader>
                <Heading.H2>Completed Tasks</Heading.H2>
                <StyledCompletedButton>
                  <DownloadDocumentsButton subject={subject} />
                </StyledCompletedButton>
              </CompletedHeader>
            </Margin>
            <GroupedTaskList
              milestones={milestones}
              tasks={completedTasks}
              projectId={id}
              setProject={setProject}
              projectStatus={subject.status}
            />
          </ListSection>
        )}

        <StyledRecaptchaDisclaimer>
          This site is protected by reCAPTCHA and the Google{' '}
          <a href="https://policies.google.com/privacy">Privacy Policy</a> and{' '}
          <a href="https://policies.google.com/terms">Terms of Service</a> apply.
        </StyledRecaptchaDisclaimer>
      </Container>
    </RecaptchaProvider>
  );
};
