import { toUpper } from 'lodash';
import React from 'react';
import { Control, useController } from 'react-hook-form';
import styled, { css } from 'styled-components';
import { Margin } from 'styled-components-spacing';
import { ReactComponent as ErrorIcon } from '../../../svg/error.svg';
import { ReactComponent as TickIcon } from '../../../svg/tick.svg';
import { Heading } from '../../Heading';
import { FieldError } from '../FieldError';
import { StyledInput } from '../StyledInput';

const iconStyle = css`
  height: 18px;
  width: 18px;
`;
const StyledErrorIcon = styled(ErrorIcon as React.ComponentType<any>)`
  ${iconStyle}
  margin-left: -2.5em;
  pointer-events: none;
`;

const StyledTickIcon = styled(TickIcon as React.ComponentType<any>)`
  ${iconStyle}
  margin-left: -2.5em;
  pointer-events: none;
`;

type TextBoxProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  name: string;
  label: string;
  labelComponent?: React.ReactNode;
  errors?: string;
  maxLength?: number;
  className?: string;
  readOnly?: boolean;
  forceUppercase?: boolean;
};
const StyledFlexDiv = styled.div`
  display: flex;
  align-items: center;
`;

/**
 * Text input for forms in the project.
 */
export const TextBox = ({
  name,
  label,
  className,
  labelComponent,
  errors,
  maxLength,
  control,
  readOnly,
  forceUppercase,
}: TextBoxProps): JSX.Element => {
  const {
    field: { onBlur, onChange, ref, value },
    meta: { invalid, isTouched },
  } = useController({ name, control });

  return (
    <Margin className={className} top={{ mobile: 3, tablet: 4, desktop: 5 }}>
      <label htmlFor={name}>{labelComponent || <Heading.H5>{label}</Heading.H5>}</label>
      <StyledFlexDiv>
        <StyledInput
          id={name}
          name={name}
          ref={ref}
          value={value}
          error={invalid}
          maxLength={maxLength}
          onBlur={onBlur}
          onChange={(e) => {
            onChange(forceUppercase ? toUpper(e.target.value) : e.target.value);
          }}
          readOnly={readOnly}
        />
        {isTouched && !errors && <StyledTickIcon />}
        {invalid && <StyledErrorIcon />}
      </StyledFlexDiv>
      {errors && <FieldError>{errors}</FieldError>}
    </Margin>
  );
};
