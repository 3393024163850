import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { colorBlue, colorWhite, outlineBlue } from '../../../../theme.selectors';
import { ReactComponent as UploadIcon } from '../../../../svg/upload.svg';
import { BaseButton } from '../../../Button/BaseButton';
import breakpoint from 'styled-components-breakpoint';

const StyledFileInput = styled.input`
  font-size: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
`;

const StyledButton = styled(BaseButton)<{ $progress: number }>`
  box-sizing: border-box;
  height: 48px;
  min-width: 186px;
  padding: 0 16px;
  border: 2px solid ${colorBlue};
  color: ${colorBlue};
  border-radius: 28px;
  background-color: ${colorWhite};
  cursor: pointer;
  &:focus {
    outline: 2px solid ${outlineBlue};
    outline-offset: 1px;
  }
  &:active {
    outline: none;
  }
  &:hover:enabled {
    border: none;
    color: #fff;
    background-color: ${colorBlue};
  }

  &:active:enabled {
    border: none;
    color: #fff;
    background-color: ${colorBlue};
    opacity: 0.6;
  }

  &:disabled {
    opacity: 0.4;
    cursor: auto;
  }

  ${breakpoint('desktop')`
    padding: 0 24px;
  `}
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  transition: 0.2s;
  background-image: ${({ theme, $progress }) =>
    `linear-gradient(to right, ${theme.colours.lightGray} ${$progress}%, ${theme.colours.white} ${$progress}%)`};

  svg {
    margin-right: 7px;
  }
`;

const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  input[type=file], /* FF, IE7+, chrome (except button) */
  input[type=file]::-webkit-file-upload-button {
    /* chromes and blink button */
    cursor: pointer;
  }
  &:hover {
    path {
      fill: white;
    }
    ${StyledButton} {
      border: none;
      color: #fff;
      background-color: ${colorBlue};
      background-image: none;
      cursor: pointer;
    }
  }
`;

type UploadButtonProps = {
  className?: string;
  label: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  progress?: number;
};

export const UploadButton = forwardRef<HTMLInputElement, UploadButtonProps>(
  ({ className, label, onChange, progress = 0 }, ref): JSX.Element => (
    <Wrapper className={className}>
      <StyledButton trackingId={'File upload'} $progress={progress}>
        <UploadIcon />
        {label}
      </StyledButton>
      <StyledFileInput
        ref={ref}
        type="file"
        name="fileUpload"
        accept=".jpg, .jpeg, .png, .heic, .pdf, .docx"
        onChange={onChange}
        title={''}
      />
    </Wrapper>
  ),
);

UploadButton.displayName = 'UploadButton';
