import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { Margin } from 'styled-components-spacing';
import { Subject } from '../../../hooks/useSubject';
import { colorWhite, colorBlack, outlineBlue, bodyFamily } from '../../../theme.selectors';
import { BaseButton } from '../../Button/BaseButton';
import { PaymentStatus } from './payment-status';

export type PaymentFormProps = {
  onPayment: (status: PaymentStatus) => void;
  subject: Subject;
  isDisabled?: boolean;
};

export const StyledPrimaryButton = styled(BaseButton as React.ComponentType<any>)`
  height: 48px;
  width: 336px;
  border-radius: 27px;
  background-color: ${colorBlack};
  font-family: ${bodyFamily};
  font-size: 18px;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  color: ${colorWhite};
  border: none;
  cursor: pointer;
  &:hover {
    background-color: ${colorBlack};
    color: ${colorWhite};
    opacity: 0.7;
  }
  &:focus {
    outline: 2px solid ${outlineBlue};
    opacity: 0.5;
    background-color: ${colorBlack};
    color: ${colorWhite};
  }
  &:active {
    outline: none;
  }

  &:active:enabled {
    border: none;
    color: ${colorBlack};
    background-color: #d9d9d7;
  }

  &:disabled {
    opacity: 0.5;
    cursor: auto;
  }
  color: ${colorWhite};
  background-color: ${colorBlack};
  border: 2px solid ${colorWhite};
  width: 100%;
  ${breakpoint('tablet')`
      height: 48px;
      width: 225px;
  `}
`;
export const ButtonWrapper = styled(Margin as React.ComponentType<any>)`
  text-align: right;
`;
