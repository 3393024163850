import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useApiRequest, useErrorRedirect } from '../../hooks/useApiRequest';
import { Subject, useSubject } from '../../hooks/useSubject';

export type WithSubjectProps = { subject: Subject | null; loading: boolean; setSubject: (subject: Subject) => void };
type InputProps = Omit<WithSubjectProps, 'subject' | 'loading' | 'setSubject'>;

export const WithSubject = <TInput extends WithSubjectProps, TOutput extends InputProps>(
  Component: React.FC<WithSubjectProps>,
): React.FC<TOutput> => {
  const hoc: React.FC<TOutput> = (props) => {
    const { subjectId } = useParams<{ subjectId: string }>();
    const subjectType = useSubject();
    const [subject, setSubject] = useState<Subject | null>(null);

    const apiRequestUrl = `${subjectType}/${subjectId}`;

    const [{ data, error, loading }, fetchSubject] = useApiRequest<Subject>(
      {
        method: 'get',
        url: apiRequestUrl,
      },
      {
        manual: true,
        authRedirect: subjectType === 'projects',
      },
    );

    useEffect(() => {
      fetchSubject();
    }, []);
    useEffect(() => {
      setSubject(data ?? null);
    }, [data]);

    useErrorRedirect([error]);

    if (error) return null;

    return <Component {...(props as unknown as TInput)} subject={subject} loading={loading} setSubject={setSubject} />;
  };

  hoc.displayName = `${Component.displayName}WithSubject`;

  return hoc;
};
