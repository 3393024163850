import axios from 'axios';
import React, { useRef, useState } from 'react';
import { Margin } from 'styled-components-spacing';
import { useApiRequest } from '../../../../hooks/useApiRequest';
import { DocumentResponse, UploadedFile } from '../../../../services/openapi.types.generated';
import { FileBox } from '../FileBox';
import { UploadButton } from '../UploadButton';

const maxFileSizeMB: number = parseInt(process.env.REACT_APP_MAX_FILE_SIZE ?? '100');

export type FileListItem = { file: File; isUploaded?: boolean; id?: string; isError?: boolean };
type FileUploadProps = {
  updateProjectForFiles: (fileList: FileListItem[]) => Promise<void>;
  onLoadingChanged: (isLoading: boolean) => void;
  requestCaptcha?: () => Promise<string | null>;
  existingFiles?: UploadedFile[];
  setErrorMessage: (val: string | null) => void;
};

export const FileUpload = ({
  updateProjectForFiles,
  onLoadingChanged,
  requestCaptcha,
  existingFiles,
  setErrorMessage: setParentErrorMessage,
}: FileUploadProps): JSX.Element => {
  const [recaptcha, setRecaptcha] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [fileList, setFileList] = useState<FileListItem[]>([]);
  const [status, setStatus] = useState<'complete' | 'uploading' | 'error' | null>(null);
  const [progress, setProgress] = useState<number | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [, createDocumentRequest] = useApiRequest<DocumentResponse>(
    {
      url: 'documents',
      method: 'post',
    },
    {
      manual: true,
      authRedirect: false,
    },
  );

  const uploadDocument = async ({ file, document }: { file: File; document: DocumentResponse }) => {
    try {
      setStatus('uploading');
      const { url, uploadToken } = document;
      console.dir(document);
      await axios.put(`${url}?${uploadToken}`, file, {
        headers: {
          'x-ms-blob-type': 'BlockBlob',
        },
        onUploadProgress: (progressEvent) => {
          console.log(progressEvent.progress ?? 0);
          const percentCompleted = Math.round((progressEvent.progress ?? 0) * 100);
          setProgress(Math.min(percentCompleted, 95)); // stall at 95% until document attached to job
        },
      });

      setProgress(95); // stall at 95% until document attached to job
      console.log('finished upload');
    } catch (e) {
      setErrorMessage('Failed to upload file');
      console.error(e);
      throw e;
    }
  };

  const removeFileFromArray = (fileToRemove: FileListItem) => {
    const newFileList = fileList.filter((file) => file != fileToRemove);
    setFileList(newFileList);
    updateProjectForFiles(newFileList);
    updateParentLoadingChanged(newFileList);
  };

  const updateParentLoadingChanged = (fileList: FileListItem[]) => {
    if (!fileList.length) {
      onLoadingChanged(false);
      return;
    }
    const isLoading = fileList.some((file) => !file.isUploaded);
    onLoadingChanged(isLoading);
  };

  const handleFileInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setParentErrorMessage(null);
    const files = Array.from(event.target.files ?? []);

    if (!files || files?.length == 0) {
      setParentErrorMessage('No file selected.');
      return;
    }
    // MB * bytes conversion
    if (files.some((file) => file.size >= maxFileSizeMB * 1048576)) {
      setParentErrorMessage(`File cannot be larger than ${maxFileSizeMB}MB.`);
      return;
    }

    const updatedFileList: FileListItem[] = [
      ...fileList,
      ...files.map((x) => ({
        file: x,
      })),
    ];
    if (!!requestCaptcha) {
      const recaptchaResult = await requestCaptcha();
      setRecaptcha(recaptchaResult);
    }

    setFileList(updatedFileList);
    updateProjectForFiles(updatedFileList);
    updateParentLoadingChanged(updatedFileList);

    for await (const file of updatedFileList.filter((x) => files.includes(x.file))) {
      const document = await createDocumentRequest({
        data: {
          name: file.file.name,
          recaptcha: recaptcha,
          size: file.file.size,
          type: file.file.type,
        },
      });
      console.log('Uploading file', file.file.name);
      await uploadDocument({ file: file.file, document: document!.data });
      console.log('Finished', file.file.name);
      console.log('setFileCompleted', fileList);
      file.isUploaded = true;
      file.isError = false;
      file.id = document.data.id;
      console.log('setFileCompleted', file.file.name);

      setStatus('complete');
      setProgress(100);
    }

    updateParentLoadingChanged(updatedFileList);
    await updateProjectForFiles(updatedFileList);

    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  return (
    <>
      <UploadButton label="Upload a file" onChange={handleFileInputChange} ref={fileInputRef} />

      {fileList?.length ? (
        <Margin top={{ mobile: 2, tablet: 3, desktop: 5 }}>
          {fileList.map(
            (file, index) =>
              file &&
              !existingFiles?.some((projFile) => projFile.id === file.id) && (
                <FileBox
                  key={file?.file?.name + index}
                  file={file.file}
                  progress={progress ?? 0}
                  status={status}
                  errorMessage={errorMessage}
                  onCancel={() => removeFileFromArray(file)}
                />
              ),
          )}
        </Margin>
      ) : null}
    </>
  );
};
