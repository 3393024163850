import React, { useMemo, useState } from 'react';
import { Collapse, Divider, Space } from 'antd';
import styled from 'styled-components';
import { Milestone, TaskStatus } from '../../../services/openapi.types.generated';
import { groupBy } from 'lodash';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Heading } from '../../Heading';
import { TaskList, TaskListProps } from '../../Task/TaskList';
import { hasImportantInformation } from '../../Task/ActionTask/util';

const StyledCollapse = styled(Collapse as React.ComponentType<any>)`
  .ant-collapse-header {
    padding: 0px !important;
  }
  .ant-collapse-header-text {
    width: 100%;
  }
`;
const StyledCollapsePanel = styled(Collapse.Panel as React.ComponentType<any>)`
  .ant-collapse-content-box {
    padding: 0px !important;
  }
`;
const CollapseHeaderItem = styled.span`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
`;
type CollapseHeader = { milestone: Milestone; isVisible: boolean };
const CollapseHeader = ({ milestone, isVisible }: CollapseHeader) => (
  <Divider orientation="left" orientationMargin="0">
    <Space>
      <CollapseHeaderItem>{isVisible ? <UpOutlined /> : <DownOutlined />}</CollapseHeaderItem>
      <CollapseHeaderItem>
        <Heading.H5>{milestone.title}</Heading.H5>
      </CollapseHeaderItem>
    </Space>
  </Divider>
);

type GroupedTaskListProps = TaskListProps & { milestones: Milestone[] };

export const GroupedTaskList = (Component: React.FC<TaskListProps>): React.FC<GroupedTaskListProps> => {
  const HocComponent = (props: GroupedTaskListProps) => {
    const { tasks, milestones, projectStatus } = props ?? { milestones: [], tasks: [] };

    const indexedTasks = useMemo(() => groupBy(tasks, (task) => task.milestoneId), [tasks]);
    const resolvedMilestones = useMemo(
      () =>
        [...milestones]
          .slice()
          .reverse()
          .filter((milestone) => milestone?.id && indexedTasks[milestone.id]?.length),
      [milestones],
    );

    if (!tasks?.length || !resolvedMilestones?.length) {
      return <></>;
    }

    const defaultActiveMilestones =
      resolvedMilestones
        .filter(
          (milestone) =>
            (milestone.status === 'in-progress' &&
              indexedTasks[milestone.id]?.every((task) => task.status !== TaskStatus.COMPLETE)) ||
            indexedTasks[milestone.id]?.some((task) => hasImportantInformation({ ...task, projectStatus })),
        )
        .map(({ id }) => id) ?? [];

    const [activeKeys, setActiveKeys] = useState(defaultActiveMilestones);

    return (
      <StyledCollapse
        bordered={false}
        defaultActiveKey={activeKeys}
        ghost
        onChange={(key: string | string[]) => setActiveKeys(Array.isArray(key) ? key : [key])}
      >
        {resolvedMilestones.map((milestone) => {
          return (
            <StyledCollapsePanel
              key={milestone.id}
              header={<CollapseHeader milestone={milestone} isVisible={activeKeys.includes(milestone.id)} />}
              showArrow={false}
            >
              <Component {...props} tasks={indexedTasks[milestone.id]} />
            </StyledCollapsePanel>
          );
        })}
      </StyledCollapse>
    );
  };

  HocComponent.displayName = 'GroupedTaskList';

  return HocComponent;
};

export const TaskListWithIndex = GroupedTaskList(TaskList);
