import styled, { keyframes } from 'styled-components';
import { ReactComponent as LoadingIcon } from '../../../svg/approved.svg';

const fade = keyframes`
  0%   { opacity:1; }
  50%  { opacity:0.1; }
  100% { opacity:1; }
`;
export const Loading = styled(LoadingIcon as React.ComponentType<any>)`
  width: 64px;
  height: 64px;
  animation: ${fade} 1.5s infinite;
`;
