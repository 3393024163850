import React from 'react';
import { Heading } from '../../Heading';
import styled, { css } from 'styled-components';
import { ReactComponent as ErrorIcon } from '../../../svg/error.svg';
import { ReactComponent as TickIcon } from '../../../svg/tick.svg';
import { inputFontSize, bodyFamily, inputBorderRadius, inputPadding } from '../../../theme.selectors';
import { Margin } from 'styled-components-spacing';
import { breakpoint } from 'styled-components-breakpoint';
import { Control, useController } from 'react-hook-form';
import { FieldError } from '../FieldError';

type StyledAreaProps = {
  error: boolean;
};

const inputStyle = css`
  font-family: ${bodyFamily};
  box-sizing: border-box;
  border-radius: ${inputBorderRadius};
  font-size: ${inputFontSize};
  padding: ${inputPadding};
  width: 100%;
  max-width: auto;
  ${breakpoint('mobile')`
    height: 324px;
  `}
  ${breakpoint('tablet')`
    height: 344px;
  `}
   ${breakpoint('desktop')`
    height: 299px;
  `};
`;

const StyledInput = styled.textarea<StyledAreaProps>`
  ${inputStyle}
  border: 1px solid ${({ error, theme }): string => (error ? theme.colours.red : theme.colours.borderColor)};
`;

const iconStyle = css`
  height: 18px;
  width: 18px;
  margin-top: 1.5em;
  margin-left: -2.5em;
  pointer-events: none;
  z-index: 1; // fixes display error in chrome, opera and edge.
`;
const StyledErrorIcon = styled(ErrorIcon as React.ComponentType<any>)`
  ${iconStyle}
`;

const StyledTickIcon = styled(TickIcon as React.ComponentType<any>)`
  ${iconStyle}
`;
const FlexDiv = styled.div`
  display: flex;
`;

type TextAreaProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  name: string;
  label: string;
  errors?: string;
};

/**
 * Text area input for forms in the project. Used for large block of text in the forms
 */
export const TextArea = ({ name, label, errors, control }: TextAreaProps): JSX.Element => {
  const {
    field: { onBlur, onChange, ref, value },
    meta: { invalid, isTouched },
  } = useController({ name, control });

  return (
    <Margin top={{ mobile: 2, tablet: 3, desktop: 4 }}>
      <label htmlFor={name}>
        <Heading.H5>{label}</Heading.H5>
      </label>
      <FlexDiv>
        <StyledInput
          id={name}
          name={name}
          ref={ref}
          value={value}
          error={invalid}
          onBlur={onBlur}
          onChange={onChange}
        />
        {isTouched && !errors && <StyledTickIcon />}
        {errors && <StyledErrorIcon />}
      </FlexDiv>
      {errors && <FieldError>{errors}</FieldError>}
    </Margin>
  );
};
